import React, { useState, useRef, useEffect } from "react";
import ReactDatePicker from "../../common/ReactDatePicker";
import DataTableBase from "../../common/DateTableBase";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../../asserts/logo.png";
import ReactToPrint from 'react-to-print';
import * as XLSX from 'xlsx/xlsx.mjs';
import axios from "axios";
import { API_URL } from "../../../utility";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../common/Loader";

const dataa = [
    {
        id:1,
        date: '16-09-2023',
        time: '12:00',
        description: "description adfadf...",
        status: 'Active',
        wallet: 2000
    },
    {
        id:2,
        date: '16-09-2023',
        time: '12:00',
        description: "description adfadf...",
        status: 'Inactive',
        wallet: 5000
    },
    {
        id:3,
        date: '16-09-2023',
        time: '12:00',
        description: "description adfadf...",
        status: 'Pending',
        wallet: 3000
    },
    {
        id:4,
        date: '16-09-2023',
        time: '12:00',
        description: "description adfadf...",
        status: 'Incomplete',
        wallet: 5000,
        used: 1000
    },
]

const StaffToppingReport = () => {
    const [isChecked, setIsChecked] = useState(false);

    const navigate = useNavigate();
    
    const [month, setMonth] = useState(null);
    const [year, setYear] = useState(null);
    const [loading, setLoading] = useState(true);
    const [flag, setFlag] = useState(false);
    const [data, setData] = useState([]);
    
    const {id, sid, name, vcard} = useParams();

    const componentRef = useRef();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        console.log("flag : ", flag);
        // if(!flag){
            console.log("if flag : ", flag);
            const currentDate = new Date();
            // Get current year
            const currentYear = currentDate.getFullYear();
            // Get current month
            const currentMonth = currentDate.getMonth() + 1; // Adding 1 because January is represented as 0
            // Format month to have leading zero if necessary
            const formattedMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
            // console.log("token : ", token);
            // axios.post(`${API_URL}/admin/users/vcardReport/${vcard}/topping`,{month: '03', year: '2024'}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            axios.post(`${API_URL}/admin/users/vcardReport/${vcard}/topping`,{month: formattedMonth, year: currentYear}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
                console.log("Staff res : ", res);
                // setStaff(res?.data?.staffDetail);
                setData(res?.data?.data);
                setLoading(false);
                // setFlag(true);
                if(localStorage.getItem("topping-twice") === "1"){
                    localStorage.setItem("topping-twice", "0");                    
                } else {
                    if(res?.data?.error){
                        toast.error(res?.data?.error);
                        localStorage.setItem("topping-twice", "1");
                    }
                }                
            }).catch((err) => {
                setLoading(false);
                setFlag(true);
                if(err?.response?.data?.error){
                    toast.error(err?.response?.data?.error?.month ? err?.response?.data?.error?.month[0] : err?.response?.data?.error?.year ? err?.response?.data?.error?.year[0] : err?.response?.data?.error?.vcard ? err?.response?.data?.error?.vcard[0] : "Server Error");
                } 
                console.log("staff error : ", err);
            })
        // }
    }, [])

    const columns = [
        {
            name: 'No.',
            width: '125px',
            selector: (row, index) => index + 1,
        },
        {
            name: 'Date',
            width: '125px',
            selector: row => {
                if(row?.date_time){
                    // let date = new Date(row?.created_at);
                    let date = row?.date_time;
                    let formateDate = date?.split(' ')[0];
                    return formateDate;                
                }
            },
        },
        {
            name: 'Time',
            width: '125px',
            selector: row => {
                if(row?.date_time){
                    // let date = new Date(row?.date_time);
                    let date = row?.date_time;
                    let formateDate = date?.split(' ')[1];
                    // let time = formateDate?.split('.')[0];
                    return formateDate;
                }
            },
        },
        {
            name: 'VCard',
            width: '130px',
            selector: row => vcard,
        },
        // {
        //     name: 'Order Id',
        //     width: '130px',
        //     selector: row => row?.order_id,
        // },
        // {
        //     name: 'Description',
        //     selector: row => row?.description,
        //     // width: '350px'
        // },
        // {
        //     name: 'Transfer Type',
        //     selector: row => row?.transfer_type,
        // },
        // {
        //     name: 'Status',
        //     selector: row => <span className={row?.status === 'Active' ? "text-success" : row?.status === 'Inactive'? "text-danger" : row?.status === 'Pending' ? "text-warning" : "text-secondary"}>{row?.status}</span>
        // },
        {
            name: 'Message',
            // selector: row => <span className={row?.status === 'Success' ? "text-success" : row?.status === 'Pending'? "text-warning" : "text-danger"}>{row?.status}</span>,
            selector: row => row?.add_type?.textEN,
        },
        {
            name: 'Amount',
            selector: row => (Number(row?.amount).toLocaleString()),
        },
        // {
        //     name: 'Tools',
        //     selector: row => <>
        //         <svg onClick={() => navigate('/staff-detail/1')} className="pointer me-1" xmlns="http://www.w3.org/2000/svg" width="32.083" height="25.667" viewBox="1600.797 306.958 32.083 25.667"><path d="M1616.036 314.979a4.012 4.012 0 0 0-4.01 4.01 4.012 4.012 0 0 0 4.01 4.01 4.012 4.012 0 0 0 4.01-4.01 4.012 4.012 0 0 0-4.01-4.01Zm13.636-8.021h-25.667a3.218 3.218 0 0 0-3.208 3.208v19.25a3.218 3.218 0 0 0 3.208 3.208h25.667a3.218 3.218 0 0 0 3.208-3.208v-19.25a3.218 3.218 0 0 0-3.208-3.208Zm-5.15 22.795-4.668-4.668a7.113 7.113 0 0 1-3.834 1.123c-3.978 0-7.202-3.225-7.202-7.219a7.21 7.21 0 0 1 7.218-7.219 7.21 7.21 0 0 1 7.22 7.219 7.154 7.154 0 0 1-1.124 3.834l4.668 4.652-2.277 2.278Z" fill="#303778" fill-rule="evenodd" data-name="Icon material-pageview"/></svg>
        //         <svg onClick={() => navigate('/staff/edit/1')} className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="33.75" height="29.994" viewBox="1652.88 302.958 33.75 29.994"><path d="m1676.47 307.827 5.285 5.285a.573.573 0 0 1 0 .809l-12.797 12.797-5.437.603a1.14 1.14 0 0 1-1.26-1.26l.603-5.437 12.797-12.797a.573.573 0 0 1 .809 0Zm9.492-1.342-2.86-2.86a2.292 2.292 0 0 0-3.234 0l-2.074 2.075a.573.573 0 0 0 0 .809l5.285 5.285a.573.573 0 0 0 .809 0l2.074-2.075c.89-.896.89-2.343 0-3.234Zm-10.582 16.752v5.965h-18.75v-18.75h13.465a.72.72 0 0 0 .498-.205l2.344-2.344a.703.703 0 0 0-.499-1.201h-16.746a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h20.625a2.813 2.813 0 0 0 2.813-2.813v-9.246c0-.627-.756-.937-1.201-.498l-2.344 2.344a.72.72 0 0 0-.205.498Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
        //         <svg  className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="22.5" height="30" viewBox="1706.63 302.958 22.5 30"><path d="M1726.317 306.708h-4.687a3.753 3.753 0 0 0-3.75-3.75 3.753 3.753 0 0 0-3.75 3.75h-4.688a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h16.875a2.813 2.813 0 0 0 2.813-2.813V309.52a2.813 2.813 0 0 0-2.813-2.812Zm-14.062 21.094c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm5.625-11.25c.78 0 1.406.626 1.406 1.406 0 .78-.627 1.406-1.406 1.406-.78 0-1.406-.627-1.406-1.406 0-.78.627-1.406 1.406-1.406Zm7.5 21.562a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
        //         <svg className="pointer ms-1" xmlns="http://www.w3.org/2000/svg" width="24.37" height="29.994" viewBox="1749.13 302.957 24.37 29.994"><path d="M1751.004 312.33v18.747a1.88 1.88 0 0 0 1.875 1.874h16.872a1.88 1.88 0 0 0 1.874-1.874V312.33h-20.62Zm5.624 16.872h-1.874v-13.123h1.874v13.123Zm3.75 0h-1.875v-13.123h1.875v13.123Zm3.749 0h-1.875v-13.123h1.875v13.123Zm3.75 0h-1.875v-13.123h1.874v13.123Zm4.217-22.496h-6.092v-2.343a1.41 1.41 0 0 0-1.406-1.406h-6.562a1.41 1.41 0 0 0-1.406 1.406v2.343h-6.092a1.41 1.41 0 0 0-1.406 1.406v2.344h24.37v-2.344a1.41 1.41 0 0 0-1.406-1.406Zm-7.967 0h-5.624v-1.85h5.624v1.85Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
        //     </>
        // }
    ];

    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [18, 20, 20, 22]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }


    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    function handleDownloadExcel(fileData1) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData1 = [];
        const StructureData = fileData1?.filter(item => {
            const obj = {"Date": item?.date, "Time": item?.time, "Description": item?.description, "Add Fund": item?.wallet};
            structuredData1?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData1)
        
        downloadExcelFile(structuredData1, `Funds range ${isoDateTime}`);
    }

    const filterFunc = () => {
        setLoading(true);
        const token = localStorage.getItem("admin_token");
        // axios.post(`${API_URL}/admin/users/vcardReport/${vcard}/topping`,{month: '03', year: '2024'}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
        axios.post(`${API_URL}/admin/users/vcardReport/${vcard}/topping`,{month: month, year: year}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Staff res : ", res);
            // setStaff(res?.data?.staffDetail);
            setData(res?.data?.data);
            setLoading(false);
            if(res?.data?.error){
                toast.error(res?.data?.error);
            }
        }).catch((err) => {
            setLoading(false);
            if(err?.response?.data?.error){
                toast.error(err?.response?.data?.error?.month ? err?.response?.data?.error?.month[0] : err?.response?.data?.error?.year ? err?.response?.data?.error?.year[0] : err?.response?.data?.error?.vcard ? err?.response?.data?.error?.vcard[0] : "Server Error");
            } 
            console.log("staff error : ", err);
        })
    }

    const currentDate = new Date();
    // Get current month (January is 0)
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    // Get current year
    const currentYear = currentDate.getFullYear().toString();

    return (
        <div className="staff-detail-page">
            <ToastContainer />
            <div style={{ display: 'none' }}>
                <ComponentToPrint ref={componentRef} />
            </div>
            <h1 className="staffdetail-t1">{name}</h1>
            <h6 className="staffdetail-t2"> 
                <span className="pointer" onClick={() => navigate(`/users`)}>User</span> 
                <span className="greater-than"> &#62; </span> 
                <span className="pointer" onClick={() => navigate(`/view-user/${id}`)}>{name}</span>
                <span className="greater-than"> &#62; </span> 
                <span className="pointer" onClick={() => navigate(`/user/staff-list/${name}/${id}`)}>List VCard</span>
                <span className="greater-than"> &#62; </span> 
                <span className="pointer" onClick={() => navigate(`/user/staff-list/${name}/${id}`)}>{vcard}</span>
                <span className="greater-than"> &#62; </span> 
                <span className="pointer" onClick={() => navigate(`/user/staff-list/${name}/${id}/view-staff/${vcard}/${sid}`)}>View VCard</span>
                <span className="greater-than"> &#62; </span> 
                <span className="staffdetail-t3">Topping Report</span>
            </h6>

            <div className="d-flex mb-4 pb-2 mt-5">
                <div className="w-50 d-flex">
                    {/* <div className="report-t1 pt-1 pe-2">
                        Select Range
                    </div> */}
                    <div>
                        <select defaultValue={currentMonth} className="form-control" onChange={(e) => setMonth(e.target.value)}>
                            <option>Select Month</option>
                            <option value="01">Jan</option>
                            <option value="02">Feb</option>
                            <option value="03">Mar</option>
                            <option value="04">Apr</option>
                            <option value="05">May</option>
                            <option value="06">Jun</option>
                            <option value="07">Jul</option>
                            <option value="08">Aug</option>
                            <option value="09">Sep</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                        </select>
                    </div>
                    <div className="mx-2 pt-1 mt-1"> - </div>
                    <div>
                        <select defaultValue={currentYear} className="form-control" onChange={(e) => setYear(e.target.value)}>
                            <option>Select Year</option>
                            <option value="2020">2020</option>
                            <option value="2021">2021</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                        </select>
                    </div>
                    <div>
                        <button className="btn-1 px-4 ms-2" onClick={() => filterFunc()}>Filter</button>
                    </div>
                </div>
                <div className="w-50 d-flex justify-content-end">
                    <div>
                        <ReactToPrint
                            trigger={() => (
                                <button className="btn-2 px-4 ms-2">
                                    Print
                                    <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="17.456" height="17.456" viewBox="12542.733 -17629.979 17.456 17.456"><g data-name="CompositeLayer"><path d="M12546.224-17619.504h10.474v6.983h-10.474v-6.983Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 37"/><path d="M12546.224-17616.014h-1.746a1.746 1.746 0 0 1-1.745-1.745v-4.364c0-.965.781-1.746 1.745-1.746h13.966c.964 0 1.746.781 1.746 1.746v4.364c0 .964-.782 1.745-1.746 1.745h-1.746" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 36"/><path d="M12546.224-17623.869v-6.11h10.474v6.11" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 35"/></g></svg>
                                </button>
                            )}
                            content={() => componentRef.current}
                        />
                    </div>
                    <div>
                        <button className="btn-1 px-3 ms-2" onClick={() => handleDownloadExcel(dataa)}>
                            Download
                            <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="20.948" height="20.948" viewBox="90.776 9.776 20.948 20.948"><path d="M99.613 9.776h3.274a.98.98 0 0 1 .982.982v6.873h3.588c.728 0 1.092.88.577 1.396l-6.223 6.227a.793.793 0 0 1-1.117 0l-6.232-6.227a.817.817 0 0 1 .577-1.396h3.592v-6.873a.98.98 0 0 1 .982-.982Zm12.111 15.384v4.582a.98.98 0 0 1-.982.982H91.758a.98.98 0 0 1-.982-.982V25.16a.98.98 0 0 1 .982-.982h6.002l2.005 2.005a2.097 2.097 0 0 0 2.97 0l2.005-2.005h6.002a.98.98 0 0 1 .982.982Zm-5.073 3.6a.82.82 0 0 0-.819-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .819-.819Zm2.618 0a.82.82 0 0 0-.818-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .818-.819Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-download"/></svg>
                        </button>
                    </div>

                </div>
            </div>
            
            {/* <h3 className="staffdetail-sub-heading mt-3 mb-0">Fund detail</h3>
            <div className="d-flex mt-4 pt-2">
                <div className="div1 w-50">
                    <p className="mb-0">Range add fund total : 0</p>
                </div>
                <div className="div1 w-50">
                    <p className="mb-0 text-bold text-end">Range fund used total : 0</p>                    
                </div>
            </div> */}
            <div className="staff-table-div ">
                {loading ? (
                    <Loader />
                ) : (
                    <DataTableBase 
                        columns={columns}
                        // data={dataa}
                        data={data}
                    />
                )}
            </div>
            {/* <div className="mb-5 pt-4">
                <button className="btn-3 mt-4">Submit</button>
                <button className="btn-2 ms-4">Cancel</button>
            </div> */}
        </div>
    )
}

export default StaffToppingReport;

const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div className="w-100" ref={ref}>
            <div className="p-4">
                <h1>Fund Range</h1>
                <table className="pdf-table-print">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Description</th>
                            <th>Add Fund</th>
                        </tr>
                    </thead>
                    <tbody>
                        {dataa?.map((item, index) => (
                            <tr key={index}>
                                <th>{item?.date}</th>
                                <th>{item?.time}</th>
                                <th>{item?.description}</th>
                                {/* <th>{item?.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>}</th> */}
                                <th>{item?.wallet}</th>
                            </tr>                            
                        ))}
                    </tbody>
                </table>                
            </div>
        </div>
    )
}); 