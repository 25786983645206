import React, { useState } from "react";
// import logo from "../../../asserts/logo2.png";
import logo from "../../../asserts/new-logo.jpeg";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../utility";
import axios from "axios";
import { useForm } from "react-hook-form";

const Login = () => {
    const navigate = useNavigate();

    const [error, setError] = useState(null);

    const { register, handleSubmit, watch, formState: { errors }, } = useForm();
      

    
    
    const onSubmit = (data) => {
        console.log("data : ", data);
        axios.post(`${API_URL}/auth/admin/login`, data).then((res) => {
            console.log("login res : ", res);
            localStorage.setItem("ba_tab", "1");
            localStorage.setItem("admin_token", res?.data?.token);
            navigate('/');
        }).catch((err) => {
            console.log("Login Error : ", err);
            setError(err?.response?.data?.message);
        })
    }
    

    return (
        <div className="login-page">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="content-div">
                    <div className="text-center">
                        <img src={logo} className="logo" alt="logo" />
                        {/* <h2 className="login-heading">VOLTA FLEET SERVICE</h2> */}
                        <h5 className="login-sub-heading">Admin</h5>
                    </div>
                    <div className="d-flex mt-4">
                        <div className="w-50px">
                            <svg xmlns="http://www.w3.org/2000/svg" width="34.875" height="34.875" viewBox="757 671.563 34.875 34.875"><path d="M774.438 671.563c-9.633 0-17.438 7.804-17.438 17.437 0 9.633 7.805 17.438 17.438 17.438 9.632 0 17.437-7.805 17.437-17.438s-7.805-17.438-17.438-17.438Zm0 6.75a6.188 6.188 0 1 1 0 12.375 6.188 6.188 0 0 1 0-12.375Zm0 24.187c-4.128 0-7.826-1.87-10.301-4.795 1.322-2.49 3.91-4.205 6.926-4.205.168 0 .337.028.499.077.914.296 1.87.486 2.875.486 1.006 0 1.97-.19 2.876-.486.162-.049.33-.077.5-.077 3.016 0 5.603 1.716 6.925 4.205-2.475 2.925-6.173 4.795-10.3 4.795Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-user-circle"/></svg>
                        </div>
                        <div className="w-100-50">
                            <input type="text" {...register("email", {required: true})} className="form-control w-100" placeholder="User or Email" />
                            {errors?.email && <p className="text-danger mb-0">Email is required</p>}
                        </div>
                    </div>
                    <div className="d-flex mt-4">
                        <div className="w-50px">
                            <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="36" viewBox="760.375 744 31.5 36"><path d="M788.5 762h-17.438v-7.25c0-2.784 2.23-5.097 5.014-5.125a5.07 5.07 0 0 1 5.111 5.063v1.125c0 .935.753 1.687 1.688 1.687h2.25c.935 0 1.688-.752 1.688-1.688v-1.125c0-5.906-4.817-10.708-10.723-10.687-5.906.021-10.653 4.887-10.653 10.793V762h-1.687a3.376 3.376 0 0 0-3.375 3.375v11.25A3.376 3.376 0 0 0 763.75 780h24.75a3.376 3.376 0 0 0 3.375-3.375v-11.25A3.376 3.376 0 0 0 788.5 762Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-unlock"/></svg>
                        </div>
                        <div className="w-100-50">
                            <input type="password" {...register("password", {required: true})} className="form-control w-100" placeholder="Password" />
                            {errors?.password && <p className="text-danger mb-0">Password is required</p>}
                        </div>
                    </div>
                    <div className="d-flex mt-4">
                        <div className="w-50px">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="31.5" height="36" viewBox="760.375 744 31.5 36"><path d="M788.5 762h-17.438v-7.25c0-2.784 2.23-5.097 5.014-5.125a5.07 5.07 0 0 1 5.111 5.063v1.125c0 .935.753 1.687 1.688 1.687h2.25c.935 0 1.688-.752 1.688-1.688v-1.125c0-5.906-4.817-10.708-10.723-10.687-5.906.021-10.653 4.887-10.653 10.793V762h-1.687a3.376 3.376 0 0 0-3.375 3.375v11.25A3.376 3.376 0 0 0 763.75 780h24.75a3.376 3.376 0 0 0 3.375-3.375v-11.25A3.376 3.376 0 0 0 788.5 762Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-unlock"/></svg> */}
                        </div>
                        <div className="w-100-50">
                            <button type="submit" className="login-btn">LOGIN</button>
                            <p className="text-end login-t1" onClick={() => navigate('/forget-password')}>Forget password?</p>
                            {error && <p className="text-danger">{error}</p>}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Login;