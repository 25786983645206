import ReactDatePicker from "../../common/ReactDatePicker";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DataTableBase from "../../common/DateTableBase";
import axios from "axios";
import { API_URL, currentMonthYear } from "../../../utility";
import { useNavigate } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import * as XLSX from 'xlsx/xlsx.mjs';
import Loader from "../../common/Loader";

function formatedDate(dt){
    if(dt){
        let date = new Date(dt);
        let formateDate = date.toISOString()?.split('T')[0];
        return formateDate;                
    }
}

function formatedTime(t){
    if(t){
        let date = new Date(t);
        let formateDate = date.toISOString()?.split('T')[1];
        let time = formateDate?.split('.')[0];
        return time;
    }
}

const data1 = [
    {
        id: 1,
        status: 1,
        staff: 'Staff A',
        total: '50,000'
    },
    {
        id: 2,
        status: 1,
        staff: 'Staff B',
        total: '35,000'
    },
    {
        id: 3,
        status: 0,
        staff: 'Staff C',
        total: '55,000'
    },
    // {
    //     id: 5,
    //     status: 1,
    //     staff: 'Staff D',
    //     total: '55,000'
    // },
    // {
    //     id: 6,
    //     status: 1,
    //     staff: 'Staff E',
    //     total: '55,000'
    // },
    // {
    //     id: 7,
    //     status: 0,
    //     staff: 'Staff G',
    //     total: '55,000'
    // },
    // {
    //     id: 8,
    //     status: 1,
    //     staff: 'Staff H',
    //     total: '85,000'
    // },
]

const data2 = [
    {
        numb: '#1wq87sc6',
        date: '16-09-2023',
        time: '12:00',
        staff: 'Staff A',
        user: 'Kerry',
        transfer: 'Bank transfer',
        status: 1,
        fund: 500,
    },
    {
        numb: '#2ssa8adf2s',
        date: '16-09-2023',
        time: '12:00',
        user: 'Flash',
        staff: 'Staff B',
        transfer: 'Card',
        status: 0,
        fund: 500,
    },
    {
        numb: '#1wq87sc6',
        user: 'Kerry',
        date: '16-09-2023',
        time: '12:00',
        user: 'Kerry',
        transfer: 'Bank transfer',
        staff: 'Staff C',
        status: 1,
        fund: 500,
    },
    {
        numb: '#1wq87sc6',
        date: '16-09-2023',
        time: '12:00',
        user: 'Flash',
        transfer: 'QR Code',
        staff: 'Staff D',
        status: 0,
        fund: 500,
    },
    {
        numb: '#1wq87sc6',
        date: '16-09-2023',
        user: 'Kerry',
        time: '12:00',
        staff: 'Staff E',
        status: 1,
        transfer: 'Bank transfer',
        fund: 500,
    },
    
]



const Report = () => {
    const navigate = useNavigate();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [user, setUser] = useState('All');

    const [flag, setFlag] = useState(2);
    
    const componentRef = useRef();

    const [userList, setUserList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.post(`${API_URL}/admin/reports`, {start_date: null, end_date: null, user: 'All'}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Reports res : ", res);
            setData(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Reports error : ", err);
        })
    }, [flag])

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("User list res : ", res);
            setUserList(res?.data?.users);
            // setLoading(false);
        }).catch((err) => {
            // setLoading(false);
            console.log("staff list error : ", err);
        })
    }, [])

    

    const columns2 = [
        {
            name: 'Id',
            width: '125px',
            selector: row => row?.request_id,
        },
        {
            name: 'Date',
            width: '125px',
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[0];
                    return formateDate;                
                }
            },
        },
        {
            name: 'Time',
            width: '125px',
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[1];
                    let time = formateDate?.split('.')[0];
                    return time;
                }
            },
        },
        {
            name: 'Name',
            width: '130px',
            selector: row => row?.name,
        },
        {
            name: 'Order Id',
            width: '130px',
            selector: row => row?.order_id,
        },
        // {
        //     name: 'Description',
        //     selector: row => row?.description,
        //     // width: '350px'
        // },
        {
            name: 'Transfer Type',
            selector: row => row?.transfer_type,
        },
        // {
        //     name: 'Status',
        //     selector: row => <span className={row?.status === 'Active' ? "text-success" : row?.status === 'Inactive'? "text-danger" : row?.status === 'Pending' ? "text-warning" : "text-secondary"}>{row?.status}</span>
        // },
        {
            name: 'Amount',
            selector: row => (Number(row?.fund_amount).toLocaleString()),
        },
        {
            name: 'Status',
            selector: row => <span className={row?.status === 'Success' ? "text-success" : row?.status === 'Pending'? "text-warning" : "text-danger"}>{row?.status}</span>,
        },
        // {
        //     name: 'Tools',
        //     selector: row => <svg xmlns="http://www.w3.org/2000/svg" width="22.771" height="22.771" viewBox="1746.229 341.416 22.771 22.771"><path d="M1756.102 341.444c-5.445 0-9.873 4.429-9.873 9.873 0 5.444 4.428 9.873 9.873 9.873 1.664 0 3.3-.395 4.682-1.157.111.134.234.256.367.367l2.82 2.821a2.877 2.877 0 1 0 4.063-4.062l-2.82-2.82a2.821 2.821 0 0 0-.452-.368c.761-1.382 1.24-2.99 1.24-4.682 0-5.445-4.428-9.873-9.872-9.873Zm0 2.82a7.019 7.019 0 0 1 7.052 7.053c0 1.862-.677 3.582-1.862 4.852l-.085.084c-.133.111-.256.234-.366.367-1.241 1.129-2.934 1.777-4.768 1.777a7.019 7.019 0 0 1-7.052-7.052 7.019 7.019 0 0 1 7.052-7.052Z" fill="#dededf" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
        // },
        // {
        //     name: 'Tools',
        //     selector: row => <>
        //         <svg onClick={() => navigate('/staff-detail/1')} className="pointer me-1" xmlns="http://www.w3.org/2000/svg" width="32.083" height="25.667" viewBox="1600.797 306.958 32.083 25.667"><path d="M1616.036 314.979a4.012 4.012 0 0 0-4.01 4.01 4.012 4.012 0 0 0 4.01 4.01 4.012 4.012 0 0 0 4.01-4.01 4.012 4.012 0 0 0-4.01-4.01Zm13.636-8.021h-25.667a3.218 3.218 0 0 0-3.208 3.208v19.25a3.218 3.218 0 0 0 3.208 3.208h25.667a3.218 3.218 0 0 0 3.208-3.208v-19.25a3.218 3.218 0 0 0-3.208-3.208Zm-5.15 22.795-4.668-4.668a7.113 7.113 0 0 1-3.834 1.123c-3.978 0-7.202-3.225-7.202-7.219a7.21 7.21 0 0 1 7.218-7.219 7.21 7.21 0 0 1 7.22 7.219 7.154 7.154 0 0 1-1.124 3.834l4.668 4.652-2.277 2.278Z" fill="#303778" fill-rule="evenodd" data-name="Icon material-pageview"/></svg>
        //         <svg onClick={() => navigate('/staff/edit/1')} className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="33.75" height="29.994" viewBox="1652.88 302.958 33.75 29.994"><path d="m1676.47 307.827 5.285 5.285a.573.573 0 0 1 0 .809l-12.797 12.797-5.437.603a1.14 1.14 0 0 1-1.26-1.26l.603-5.437 12.797-12.797a.573.573 0 0 1 .809 0Zm9.492-1.342-2.86-2.86a2.292 2.292 0 0 0-3.234 0l-2.074 2.075a.573.573 0 0 0 0 .809l5.285 5.285a.573.573 0 0 0 .809 0l2.074-2.075c.89-.896.89-2.343 0-3.234Zm-10.582 16.752v5.965h-18.75v-18.75h13.465a.72.72 0 0 0 .498-.205l2.344-2.344a.703.703 0 0 0-.499-1.201h-16.746a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h20.625a2.813 2.813 0 0 0 2.813-2.813v-9.246c0-.627-.756-.937-1.201-.498l-2.344 2.344a.72.72 0 0 0-.205.498Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
        //         <svg  className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="22.5" height="30" viewBox="1706.63 302.958 22.5 30"><path d="M1726.317 306.708h-4.687a3.753 3.753 0 0 0-3.75-3.75 3.753 3.753 0 0 0-3.75 3.75h-4.688a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h16.875a2.813 2.813 0 0 0 2.813-2.813V309.52a2.813 2.813 0 0 0-2.813-2.812Zm-14.062 21.094c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm5.625-11.25c.78 0 1.406.626 1.406 1.406 0 .78-.627 1.406-1.406 1.406-.78 0-1.406-.627-1.406-1.406 0-.78.627-1.406 1.406-1.406Zm7.5 21.562a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
        //         <svg className="pointer ms-1" xmlns="http://www.w3.org/2000/svg" width="24.37" height="29.994" viewBox="1749.13 302.957 24.37 29.994"><path d="M1751.004 312.33v18.747a1.88 1.88 0 0 0 1.875 1.874h16.872a1.88 1.88 0 0 0 1.874-1.874V312.33h-20.62Zm5.624 16.872h-1.874v-13.123h1.874v13.123Zm3.75 0h-1.875v-13.123h1.875v13.123Zm3.749 0h-1.875v-13.123h1.875v13.123Zm3.75 0h-1.875v-13.123h1.874v13.123Zm4.217-22.496h-6.092v-2.343a1.41 1.41 0 0 0-1.406-1.406h-6.562a1.41 1.41 0 0 0-1.406 1.406v2.343h-6.092a1.41 1.41 0 0 0-1.406 1.406v2.344h24.37v-2.344a1.41 1.41 0 0 0-1.406-1.406Zm-7.967 0h-5.624v-1.85h5.624v1.85Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
        //     </>
        // }
    ];



    function convertToWorksheet(tableData) {
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Set width for all columns to 15 characters
        // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
        const columnWidths = [18, 20, 20, 22]

        worksheet['!cols'] = columnWidths.map(width => ({ width }));

        return worksheet;
    }


    function downloadExcelFile(tableData, fileName) {
        const worksheet = convertToWorksheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }

    function handleDownloadExcel(fileData1) {
        const now = new Date();
        const isoDateTime = now.toISOString();    
        const structuredData1 = [];
        const StructureData = fileData1?.filter(item => {
            const obj = {"Request Id": item?.request_id, "Date": formatedDate(item?.created_at), "Time": formatedTime(item?.created_at), "Order Id": item?.order_id, "Transfer Type": item?.transfer_type,  "Status": item?.status, "Amount": item?.fund_amount};
            structuredData1?.push(obj);
            return obj;
            }
        )

        // console.log("Structured data : ", StructureData)

        // console.log("Structured data 2 : ", structuredData1)
        
        downloadExcelFile(structuredData1, `Report ${isoDateTime}`);
    }

    const filteredReport = () => {
        setLoading(true);
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.post(`${API_URL}/admin/reports`, {start_date: startDate, end_date: endDate, user: user}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Reports filtered : ", res);
            setData(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Reports filtered error : ", err);
        })
    }
    
    return (
        <div className="overview">
            <div style={{ display: 'none' }}>
                <ComponentToPrint data={data} ref={componentRef} />
            </div>
                    <h1 className="overview-t1">Report</h1>
                    <div className="d-flex mb-4 pb-2 mt-5">
                        <div className="w-200px d-flex">                            
                            <div className="w-100 pe-2">
                                Select Biglot
                                <select className="form-control w-100" onChange={(e) => setUser(e.target.value)}>
                                    <option value="All">All</option>
                                    {userList?.map((item, index) => (
                                        <option key={index} value={item?.id}>{item?.name} {item?.surename}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="w-50 d-flex">
                            <div>
                                Select Range
                                <ReactDatePicker date={startDate} setDate={setStartDate} />
                            </div>
                            <div className="mx-2 pt-1 mt-1 pt-4"> - </div>
                            <div className="pt-4">
                                <ReactDatePicker date={endDate} setDate={setEndDate} />
                            </div>
                            <div className="pt-4">
                                <button className="btn-1 px-4 ms-2" onClick={filteredReport}>Filter</button>
                            </div>
                        </div>
                        <div className="w-50-200px d-flex justify-content-end">
                            <div className="pt-4">
                                <ReactToPrint
                                    trigger={() => (
                                        <button className="btn-2 px-4 ms-2">
                                            Print
                                            <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="17.456" height="17.456" viewBox="12542.733 -17629.979 17.456 17.456"><g data-name="CompositeLayer"><path d="M12546.224-17619.504h10.474v6.983h-10.474v-6.983Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 37"/><path d="M12546.224-17616.014h-1.746a1.746 1.746 0 0 1-1.745-1.745v-4.364c0-.965.781-1.746 1.745-1.746h13.966c.964 0 1.746.781 1.746 1.746v4.364c0 .964-.782 1.745-1.746 1.745h-1.746" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 36"/><path d="M12546.224-17623.869v-6.11h10.474v6.11" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 35"/></g></svg>
                                        </button>
                                    )}
                                    content={() => componentRef.current}
                                />
                            </div>
                            <div className="pt-4">
                                <button className="btn-1 px-3 ms-2" onClick={() => handleDownloadExcel(data)}>
                                    Download
                                    <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="20.948" height="20.948" viewBox="90.776 9.776 20.948 20.948"><path d="M99.613 9.776h3.274a.98.98 0 0 1 .982.982v6.873h3.588c.728 0 1.092.88.577 1.396l-6.223 6.227a.793.793 0 0 1-1.117 0l-6.232-6.227a.817.817 0 0 1 .577-1.396h3.592v-6.873a.98.98 0 0 1 .982-.982Zm12.111 15.384v4.582a.98.98 0 0 1-.982.982H91.758a.98.98 0 0 1-.982-.982V25.16a.98.98 0 0 1 .982-.982h6.002l2.005 2.005a2.097 2.097 0 0 0 2.97 0l2.005-2.005h6.002a.98.98 0 0 1 .982.982Zm-5.073 3.6a.82.82 0 0 0-.819-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .819-.819Zm2.618 0a.82.82 0 0 0-.818-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .818-.819Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-download"/></svg>
                                </button>
                            </div>

                        </div>
                    </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                    <Row className="mt-5">
                        
                        <Col className="mt-5">
                            <div className="overview-table-div2">
                                {/* <h2 className="overview-t8 pb-2">Last 10 add fund <span className="pointer" onClick={() =>{ localStorage.setItem("ba_tab", "3"); navigate('/fund-requests');}}>View all</span></h2> */}
                                <DataTableBase 
                                    columns={columns2} 
                                    data={data} 
                                    // data={[]} 
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export default Report;


const ComponentToPrint = React.forwardRef(({data}, ref) => {
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            // console.log("User list res : ", res);
            setUserList(res?.data?.users);
        }).catch((err) => {
            // console.log("staff list error : ", err);
        })
    }, [])

    // useEffect(() => {
    //     const token = localStorage.getItem("admin_token");
    //     // console.log("token : ", token);
    //     axios.get(`${API_URL}/admin/reports`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
    //         console.log("admin reports res : ", res);
    //         // setUserList(res?.data?.users);
    //     }).catch((err) => {
    //         console.log("Admin reports error : ", err);
    //     })
    // }, [])

    return (
        <div className="w-100" ref={ref}>
            <div className="p-4">
                <h1>Report</h1>
                <table className="pdf-table-print">
                    <thead>
                        <tr>
                            <th>Request Id</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Order Id</th>
                            <th>User</th>
                            <th>Transfer Type</th>
                            <th>Status</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => (
                            <tr key={index}>
                                <th>{item?.request_id}</th>
                                <th>{formatedDate(item?.created_at)}</th>
                                <th>{formatedTime(item?.created_at)}</th>
                                <th>{item?.order_id}</th>
                                <th>{item?.name}</th>
                                <th>{item?.transfer_type}</th>
                                <th>{item?.status === "Success"? <span className="active-status">Success</span> : item?.status === "Pending" ? <span className="text-warning">Pending</span> : <span className="inactive-status">Failed</span>}</th>
                                <th>{Number(item?.fund_amount).toLocaleString()}</th>
                            </tr>                            
                        ))}
                    </tbody>
                </table>
                
            </div>
        </div>
    )
}); 






// import ReactDatePicker from "../../common/ReactDatePicker";
// import React, { useEffect, useRef } from "react";
// import { Col, Row } from "react-bootstrap";
// import DataTableBase from "../../common/DateTableBase";
// import axios from "axios";
// import { API_URL, currentMonthYear } from "../../../utility";
// import { useNavigate } from "react-router-dom";
// import ReactToPrint from 'react-to-print';
// import * as XLSX from 'xlsx/xlsx.mjs';


// const data1 = [
//     {
//         id: 1,
//         status: 1,
//         staff: 'Staff A',
//         total: '50,000'
//     },
//     {
//         id: 2,
//         status: 1,
//         staff: 'Staff B',
//         total: '35,000'
//     },
//     {
//         id: 3,
//         status: 0,
//         staff: 'Staff C',
//         total: '55,000'
//     },
//     // {
//     //     id: 5,
//     //     status: 1,
//     //     staff: 'Staff D',
//     //     total: '55,000'
//     // },
//     // {
//     //     id: 6,
//     //     status: 1,
//     //     staff: 'Staff E',
//     //     total: '55,000'
//     // },
//     // {
//     //     id: 7,
//     //     status: 0,
//     //     staff: 'Staff G',
//     //     total: '55,000'
//     // },
//     // {
//     //     id: 8,
//     //     status: 1,
//     //     staff: 'Staff H',
//     //     total: '85,000'
//     // },
// ]

// const data2 = [
//     {
//         numb: '#1wq87sc6',
//         date: '16-09-2023',
//         time: '12:00',
//         staff: 'Staff A',
//         user: 'Kerry',
//         transfer: 'Bank transfer',
//         status: 1,
//         fund: 500,
//     },
//     {
//         numb: '#2ssa8adf2s',
//         date: '16-09-2023',
//         time: '12:00',
//         user: 'Flash',
//         staff: 'Staff B',
//         transfer: 'Card',
//         status: 0,
//         fund: 500,
//     },
//     {
//         numb: '#1wq87sc6',
//         user: 'Kerry',
//         date: '16-09-2023',
//         time: '12:00',
//         user: 'Kerry',
//         transfer: 'Bank transfer',
//         staff: 'Staff C',
//         status: 1,
//         fund: 500,
//     },
//     {
//         numb: '#1wq87sc6',
//         date: '16-09-2023',
//         time: '12:00',
//         user: 'Flash',
//         transfer: 'QR Code',
//         staff: 'Staff D',
//         status: 0,
//         fund: 500,
//     },
//     {
//         numb: '#1wq87sc6',
//         date: '16-09-2023',
//         user: 'Kerry',
//         time: '12:00',
//         staff: 'Staff E',
//         status: 1,
//         transfer: 'Bank transfer',
//         fund: 500,
//     },
    
// ]



// const Report = () => {
//     const navigate = useNavigate();
    
//     const componentRef = useRef();


//     useEffect(() => {
//         const token = localStorage.getItem("admin_token");
//         // console.log("token : ", token);
//         axios.get(`${API_URL}/admin/reports`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
//             console.log("Reports res : ", res);
//             // setUserList(res?.data?.users);
//         }).catch((err) => {
//             console.log("Reports error : ", err);
//         })
//     }, [])

//     const columns = [
//         {
//             name: 'No.',
//             selector: row => row.id,
//         },
//         {
//             name: 'Staff Account',
//             selector: row => row.staff,
//         },
//         {
//             name: 'Status',
//             selector: row => row.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>,
//         },
//         {
//             name: 'Total Spend',
//             selector: row => row.total,
//         }
//     ];

//     const columns2 = [
//         {
//             name: '#',
//             selector: row => row.numb,
//         },
//         {
//             name: 'Date',
//             selector: row => row.date,
//         },
//         {
//             name: 'Time',
//             selector: row => row.time,
//         },
//         {
//             name: 'User',
//             selector: row => row.user,
//         },
//         {
//             name: 'Transfer',
//             selector: row => row.transfer,
//         },
//         // {
//         //     name: 'Status',
//         //     selector: row => row.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>,
//         // },
//         {
//             name: 'Fund',
//             selector: row => row.fund,
//         },
//         {
//             name: 'Tools',
//             width: '90px',
//             selector: row => <svg xmlns="http://www.w3.org/2000/svg" width="22.771" height="22.771" viewBox="1746.229 679.584 22.771 22.771"><path d="M1756.102 679.612c-5.445 0-9.873 4.429-9.873 9.873 0 5.444 4.428 9.873 9.873 9.873 1.664 0 3.3-.395 4.682-1.157.111.134.234.256.367.367l2.82 2.821a2.877 2.877 0 1 0 4.063-4.062l-2.82-2.82a2.821 2.821 0 0 0-.452-.368c.761-1.382 1.24-2.99 1.24-4.682 0-5.445-4.428-9.873-9.872-9.873Zm0 2.82a7.019 7.019 0 0 1 7.052 7.053c0 1.862-.677 3.582-1.862 4.852l-.085.084c-.133.111-.256.234-.366.367-1.241 1.129-2.934 1.777-4.768 1.777a7.019 7.019 0 0 1-7.052-7.052 7.019 7.019 0 0 1 7.052-7.052Z" fill="#7c7f84" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
//         },
//     ];



//     function convertToWorksheet(tableData) {
//         const worksheet = XLSX.utils.json_to_sheet(tableData);

//         // Set width for all columns to 15 characters
//         // worksheet['!cols'] = Array(worksheet['!ref'].split(':')[1].charCodeAt(0) - 64).fill({ width: 25 });
//         const columnWidths = [18, 20, 20, 22]

//         worksheet['!cols'] = columnWidths.map(width => ({ width }));

//         return worksheet;
//     }


//     function downloadExcelFile(tableData, fileName) {
//         const worksheet = convertToWorksheet(tableData);
//         const workbook = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
//         XLSX.writeFile(workbook, `${fileName}.xlsx`);
//     }

//     function handleDownloadExcel(fileData1, fileData2) {
//         const now = new Date();
//         const isoDateTime = now.toISOString();    
//         const structuredData1 = [];
//         const structuredData2 = [];
//         const StructureData = fileData1?.filter(item => {
//             const obj = {"No": item?.id, "Staff Account": item?.staff, "Status": item?.status === 1 ? 'Active' : 'Inactive', "Total Spend": item?.total};
//             structuredData1?.push(obj);
//             return obj;
//             }
//         )
//         const StructureData2 = fileData2?.filter(item => {
//             const obj = {"#": item?.numb, "Staff Acccount": item?.staff, "Status": item?.status === 1 ? 'Active' : 'Inactive', "Fund": item?.fund};
//             structuredData2?.push(obj);
//             return obj;
//             }
//         )

//         // console.log("Structured data : ", StructureData)

//         // console.log("Structured data 2 : ", structuredData1)
        
//         downloadExcelFile(structuredData1, `Top 10 Spend ${isoDateTime}`);
//         downloadExcelFile(structuredData2, `Range Spend List  ${isoDateTime}`);
//     }

    
//     return (
//         <div className="overview">
//             <div style={{ display: 'none' }}>
//                 <ComponentToPrint ref={componentRef} />
//             </div>
//             <h1 className="overview-t1">Report</h1>

//             <div className="d-flex mb-4 pb-2 mt-5">
//                 <div className="w-50 d-flex">
//                     <div className="report-t1 pt-1 pe-2">
//                         Select Range
//                     </div>
//                     <div>
//                         <ReactDatePicker />
//                     </div>
//                     <div className="mx-2 pt-1 mt-1"> - </div>
//                     <div>
//                         <ReactDatePicker />
//                     </div>
//                     <div>
//                         <button className="btn-1 px-4 ms-2">Filter</button>
//                     </div>
//                 </div>
//                 <div className="w-50 d-flex justify-content-end">
//                     <div>
//                         <ReactToPrint
//                             trigger={() => (
//                                 <button className="btn-2 px-4 ms-2">
//                                     Print
//                                     <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="17.456" height="17.456" viewBox="12542.733 -17629.979 17.456 17.456"><g data-name="CompositeLayer"><path d="M12546.224-17619.504h10.474v6.983h-10.474v-6.983Z" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 37"/><path d="M12546.224-17616.014h-1.746a1.746 1.746 0 0 1-1.745-1.745v-4.364c0-.965.781-1.746 1.745-1.746h13.966c.964 0 1.746.781 1.746 1.746v4.364c0 .964-.782 1.745-1.746 1.745h-1.746" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 36"/><path d="M12546.224-17623.869v-6.11h10.474v6.11" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#303778" fill="transparent" data-name="Path 35"/></g></svg>
//                                 </button>
//                             )}
//                             content={() => componentRef.current}
//                         />
//                     </div>
//                     <div>
//                         <button className="btn-1 px-3 ms-2" onClick={() => handleDownloadExcel(data1, data2)}>
//                             Download
//                             <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="20.948" height="20.948" viewBox="90.776 9.776 20.948 20.948"><path d="M99.613 9.776h3.274a.98.98 0 0 1 .982.982v6.873h3.588c.728 0 1.092.88.577 1.396l-6.223 6.227a.793.793 0 0 1-1.117 0l-6.232-6.227a.817.817 0 0 1 .577-1.396h3.592v-6.873a.98.98 0 0 1 .982-.982Zm12.111 15.384v4.582a.98.98 0 0 1-.982.982H91.758a.98.98 0 0 1-.982-.982V25.16a.98.98 0 0 1 .982-.982h6.002l2.005 2.005a2.097 2.097 0 0 0 2.97 0l2.005-2.005h6.002a.98.98 0 0 1 .982.982Zm-5.073 3.6a.82.82 0 0 0-.819-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .819-.819Zm2.618 0a.82.82 0 0 0-.818-.818.82.82 0 0 0-.818.818c0 .45.368.819.818.819a.82.82 0 0 0 .818-.819Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-download"/></svg>
//                         </button>
//                     </div>

//                 </div>
//             </div>

//             <Row className="mt-5">
//                 <Col md={6}>
//                     <div className="overview-card">
//                         <h2 className="overview-t2">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="413 215 30 26.25"><path d="M440.023 220.625h-22.336a.937.937 0 1 1 0-1.875h22.5c.518 0 .938-.42.938-.938a2.812 2.812 0 0 0-2.813-2.812H416.75a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.262 2.977-2.813v-15c0-1.55-1.335-2.812-2.977-2.812Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.751 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg>
//                             Total add fund
//                             <span>{currentMonthYear()}</span>
//                         </h2>
//                         <h2 className="overview-t3">0 THB</h2>
//                         <h2 className="overview-t4 mt-38">TOTAL INCONE</h2>
//                         <h2 className="overview-t4 mt-3">0 THB</h2>
//                     </div>
//                 </Col>
//                 {/* <Col md={6}>
//                     <SemiPieChart />
//                 </Col>
//                 <Col md={6}>
//                     <div className="overview-card d-flex">
//                         <div className="w-50">
//                             <h6 className="overview-t2">
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="26.25" viewBox="1146.125 205.168 33.75 26.25"><path d="M1170.5 214.543c0-5.178-5.456-9.375-12.188-9.375-6.73 0-12.187 4.197-12.187 9.375 0 2.008.828 3.864 2.225 5.39-.783 1.771-2.077 3.174-2.096 3.194a.468.468 0 0 0 .34.791c2.143 0 3.921-.718 5.199-1.464 1.886.923 4.118 1.464 6.52 1.464 6.73 0 12.187-4.197 12.187-9.375Zm-13.125 5.625v-.954a3.355 3.355 0 0 1-1.838-.665.47.47 0 0 1-.033-.712l.688-.657a.48.48 0 0 1 .594-.042c.226.141.484.218.75.218h1.648c.38 0 .691-.347.691-.773 0-.349-.211-.656-.514-.746l-2.636-.791c-1.09-.327-1.85-1.372-1.85-2.542 0-1.437 1.115-2.604 2.5-2.641v-.945c0-.259.21-.469.468-.469h.938c.259 0 .468.21.468.47v.954a3.35 3.35 0 0 1 1.838.665.47.47 0 0 1 .034.711l-.689.657a.48.48 0 0 1-.593.043 1.406 1.406 0 0 0-.751-.218h-1.647c-.381 0-.692.347-.692.773 0 .348.212.655.514.745l2.637.791c1.09.327 1.85 1.373 1.85 2.543 0 1.437-1.116 2.604-2.5 2.64v.945c0 .26-.21.469-.469.469h-.937a.469.469 0 0 1-.469-.469Zm20.274 7.265c1.398-1.525 2.226-3.382 2.226-5.39 0-3.922-3.134-7.277-7.578-8.676.05.387.078.779.078 1.176 0 6.203-6.308 11.25-14.063 11.25-.631 0-1.249-.045-1.859-.11 1.848 3.37 6.182 5.735 11.235 5.735 2.4 0 4.633-.541 6.52-1.464 1.277.746 3.055 1.464 5.198 1.464a.468.468 0 0 0 .34-.791c-.019-.02-1.314-1.42-2.097-3.194Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-comments-dollar"/></svg>
//                                 <span>Spend</span>
//                             </h6>
//                             <h2 className="overview-t3">12,000,000 THB</h2>
//                             <div>
//                                 <p className="overview-t4 mb-0 pt-2 mt-4">TOTAL FUND</p>
//                                 <p className="overview-t4">158,000,587 THB</p>
//                             </div>
//                         </div>
//                         <div className="w-50 card-left-div">
//                             <h2 className="overview-t5">3,800,587 THB</h2>
//                             <h6 className="overview-t6">
//                                 <span>Remain</span>
//                                 <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="1743 390.168 30 26.25"><path d="M1770.023 395.793h-22.335a.937.937 0 1 1 0-1.875h22.5c.517 0 .937-.42.937-.937a2.812 2.812 0 0 0-2.813-2.813h-21.562a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.261 2.977-2.812v-15c0-1.551-1.335-2.813-2.977-2.813Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg>
//                             </h6>
//                         </div>
//                     </div>
//                 </Col> */}

//                 {/* <Col md={12} className="mb-4 pb-4">
//                     <h2 className="overview-t6">Spend Chart</h2>
//                     <ColumnChart />
//                 </Col> */}
//                 <Col md={6}>
//                     <div className="overview-table-div">
//                         <h2 className="overview-t7">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="30" viewBox="418 965.832 33.75 30"><path d="M450.344 969.582h-6.094v-2.344c0-.779-.627-1.406-1.406-1.406h-15.938c-.779 0-1.406.627-1.406 1.406v2.344h-6.094c-.779 0-1.406.627-1.406 1.406v3.282c0 2.091 1.318 4.242 3.627 5.9 1.846 1.33 4.09 2.174 6.445 2.443 1.84 3.053 3.99 4.313 3.99 4.313v4.219h-2.812c-2.068 0-3.75 1.212-3.75 3.28v.704c0 .387.316.703.703.703h17.344a.705.705 0 0 0 .703-.703v-.703c0-2.069-1.682-3.281-3.75-3.281h-2.813v-4.22s2.15-1.259 3.99-4.312c2.362-.27 4.606-1.113 6.446-2.443 2.303-1.658 3.627-3.809 3.627-5.9v-3.282c0-.779-.627-1.406-1.406-1.406Zm-26.526 7.547c-1.43-1.031-2.068-2.18-2.068-2.86v-.937h3.762c.058 1.91.34 3.586.75 5.05a9.443 9.443 0 0 1-2.444-1.253Zm24.182-2.86c0 .944-1.037 2.116-2.068 2.86a9.48 9.48 0 0 1-2.45 1.254c.41-1.465.692-3.14.75-5.051H448v.938Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-trophy"/></svg>
//                             TOP 3 add fund
//                             <span>{currentMonthYear()}</span>
//                         </h2>
//                         <DataTableBase 
//                             columns={columns} 
//                             // data={data1} 
//                             data={[]} 
//                         />
//                     </div>
//                 </Col> 
//                 <Col className="mt-5">
//                     <div className="overview-table-div2">
//                         <h2 className="overview-t8 pb-2">Last 10 add fund <span className="pointer" onClick={() =>{ localStorage.setItem("ba_tab", "3"); navigate('/fund-requests');}}>View all</span></h2>
//                         <DataTableBase 
//                             columns={columns2} 
//                             // data={data2} 
//                             data={[]} 
//                         />
//                     </div>
//                 </Col>
//             </Row>
//         </div>
//     )
// }

// export default Report;


// const ComponentToPrint = React.forwardRef((props, ref) => {
//     return (
//         <div className="w-100" ref={ref}>
//             <div className="p-4">
//                 <h1>Last 10 add fund</h1>
//                 <table className="pdf-table-print">
//                     <thead>
//                         <tr>
//                             <th>No.</th>
//                             <th>Staff Account</th>
//                             <th>Status</th>
//                             <th>Total Spend</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data1?.map((item, index) => (
//                             <tr key={index}>
//                                 <th>{item?.id}</th>
//                                 <th>{item?.staff}</th>
//                                 <th>{item?.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>}</th>
//                                 <th>{item?.total}</th>
//                             </tr>                            
//                         ))}
//                     </tbody>
//                 </table>
//                 <h1 className="mt-3">Top 3 add fund</h1>
//                 <table className="pdf-table-print">
//                     <thead>
//                         <tr>
//                             <th>#</th>
//                             <th>Staff Account</th>
//                             <th>Status</th>
//                             <th>Fund</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {data2?.map((item, index) => (
//                             <tr key={index}>
//                                 <th>{item?.numb}</th>
//                                 <th>{item?.staff}</th>
//                                 <th>{item?.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>}</th>
//                                 <th>{item?.fund}</th>
//                             </tr>                            
//                         ))}
//                     </tbody>
//                 </table>
//             </div>
//         </div>
//     )
// }); 
