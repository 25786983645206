import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL } from "../../../utility";
import Loader from "../../common/Loader";
import logo from "../../../asserts/logo.png";
import ReactToPrint from 'react-to-print';

function formateDate(dt){
    if(dt){
        let date = new Date(dt);
        let formateDate = date.toISOString()?.split('T')[0];
        return formateDate;                
    }
}

function formateTime(dt){
    if(dt){
        let date = new Date(dt);
        let formateDate = date.toISOString()?.split('T')[1];
        let time = formateDate?.split('.')[0];
        return time;
    }
}

function currentDate(){
    const date = new Date();
    const formatedDate = date.toISOString().split('T')[0];
    return formatedDate;
}

const FundRequestDetail = () => {

    const [data, setData] = useState([]);

    const [user, setUser] = useState(null);

    const [loading, setLoading] = useState(true);
    
    const { id } = useParams();
    const navigate = useNavigate();

    const componentRef = useRef();
    const componentRef2 = useRef();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/view-request/${id}`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Fund request detail : ", res);
            setData(res?.data?.fundView);
            setUser(res?.data?.fundView);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Fund request detail error : ", err);
        })
    }, [])


    // useEffect(() => {
    //     const token = localStorage.getItem("admin_token");
    //     // console.log("token : ", token);
    //     axios.get(`${API_URL}/user/profile`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
    //         console.log("View User res : ", res);
    //         setUser(res?.data);
    //     }).catch((err) => {
    //         // console.log("View User error : ", err);
    //     })
    // }, [])
    


    return (
        <div className="staff-detail-page">
            {loading ? (
                <Loader />
            ) : (
            <>
                <div style={{ display: 'none' }}>
                    <ComponentToPrint amount={data?.fund_amount} ref={componentRef} />
                </div>
                <div style={{ display: 'none' }}>
                    <ComponentToPrintForBill amount={data?.fund_amount} ref={componentRef2} />
                </div>
                <h1 className="staffdetail-t1">{data?.request_id}</h1>
                <h6 className="staffdetail-t2"><span onClick={() => navigate(`/fund-requests`)} className="pointer">Fund List</span>  <span className="greater-than"> &#62; </span> <span className="staffdetail-t3">{data?.request_id}</span></h6>
                <h3 className="staffdetail-sub-heading mt-3 mb-0">Fund details</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0">Fund request</p>
                        <p className="mb-0">Date</p>
                        <p className="mb-0">Time</p>
                        <p className="mb-0">Transfer</p>
                        <p className="mb-0">Status</p>
                        {/* <p className="mb-0">Email</p>
                        <p className="mb-0">Phone</p>
                        <p className="mb-0">V-Card</p>
                        <p className="mb-0">V-Card status</p> */}
                    </div>
                    <div className="div2">
                        <p className="mb-0">{data?.fund_amount}</p>
                        <p className="mb-0">{formateDate(data?.created_at)}</p>
                        <p className="mb-0">{formateTime(data?.created_at)}</p>
                        <p className="mb-0">{data?.transfer_type}</p>
                        <p className="mb-0 color-42c78f">
                            <span className={data?.status === 'Success' ? "text-success" : data?.status === 'Failed'? "text-danger" : data?.status === 'Pending' ? "text-warning" : "text-secondary"}>{data?.status}</span>
                        </p>
                        {/* <p className="mb-0">angleria_2539@gmail.com</p>
                        <p className="mb-0">0889563324</p>
                        <p className="mb-0">122546969998</p> */}
                        {/* <p className="mb-0">
                            <span className="color-42c78f">Verify</span>
                            <button className="btn-2 ms-4">Re-verify</button>
                        </p> */}
                    </div>
                </div>
                
                <h3 className="staffdetail-sub-heading mt-5">Download QT/Bill</h3>
                <div className="mt-4">
                    <ReactToPrint
                            trigger={() => (
                            <button className="btn-3">Quatation</button>
                        )}
                        content={() => componentRef.current}
                    />
                    <ReactToPrint
                            trigger={() => (
                            <button className="btn-3 btn-5 ms-3">Bill</button>
                        )}
                        content={() => componentRef2.current}
                    />
                </div>

                <h3 className="staffdetail-sub-heading mt-5 mb-0">User Detail</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0">User account</p>
                        <p className="mb-0">Status</p>
                        <p className="mb-0">Money</p>
                        <p className="mb-0">VCard</p>
                        <p className="mb-0">Name - Surname</p>
                        <p className="mb-0">Address</p>
                        <p className="mb-0">Email</p>
                        <p className="mb-0">Phone</p>
                        <p className="mb-0">Company</p>
                    </div>
                    <div className="div2">
                        <p className="mb-0">{user?.user?.user_account}</p>
                        <p className="mb-0 color-42c78f">Active</p>
                        <p className="mb-0">{Number(Number(user?.user?.money).toFixed(2)).toLocaleString()}</p>
                        <p className="mb-0">{user?.user?.vCard}</p>
                        <p className="mb-0">{user?.user?.name} {user?.user?.surename}</p>
                        <p className="mb-0">{user?.user?.address}</p>
                        <p className="mb-0">{user?.user?.email}</p>
                        <p className="mb-0">{user?.user?.phone_number}</p>
                        <p className="mb-0">{user?.user?.company}</p>
                    </div>
                </div>
                
                <h3 className="staffdetail-sub-heading mt-5 mb-0">Bill Information</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0">Company</p>
                        <p className="mb-0">Company Address</p>
                        <p className="mb-0">Phone</p>
                        <p className="mb-0">TAX ID</p>
                    </div>
                    <div className="div2 w-75">
                        <p className="mb-0">{user?.user?.bill_info?.company_name}</p>
                        <p className="mb-0">{user?.user?.bill_info?.company_address}</p>
                        <p className="mb-0">{user?.user?.bill_info?.company_phone}</p>
                        <p className="mb-0">{user?.user?.bill_info?.company_tax_id}</p>
                        {/* <p className="mb-0"><input type="text" placeholder="Enter company name" className="form-control w-100" /> </p>
                        <p className="mb-0 mt-2"><input type="text" placeholder="Enter company address" className="form-control w-100" /></p>
                        <p className="mb-0 mt-2"><input type="text" placeholder="Enter phone number" className="form-control w-100" /> </p>
                        <p className="mb-0 mt-2"><input type="text" placeholder="Enter tax ID" className="form-control w-100" /></p> */}
                    </div>
                </div>
            </>)}

            {/* <div className="mb-5 pt-4">
                <button className="btn-3 mt-4">Edit</button>
            </div> */}
        </div>
    )
}

export default FundRequestDetail;




const ComponentToPrint = React.forwardRef(({amount}, ref) => {
    const [user, setUser] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/view-request/${id}`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Fund request detail : ", res);
            // setData(res?.data?.fundView);
            setUser(res?.data?.fundView);
            // setLoading(false);
        }).catch((err) => {
            // setLoading(false);
            console.log("Fund request detail error : ", err);
        })
    }, [])

    // useEffect(() => {
    //     const token = localStorage.getItem("admin_token");
    //     // console.log("token : ", token);
    //     axios.get(`${API_URL}/user/profile`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
    //         console.log("View User res : ", res);
    //         setUser(res?.data);            
    //     }).catch((err) => {
    //         // console.log("View User error : ", err);
    //     })
    // }, [])

    return (
        <div className="w-100" ref={ref}>
            <div className="d-flex w-100 invoice-header px-4 py-3 pt-4">
                <div className="w-50">
                    <img src={logo} height={"125px"} alt="logo" />
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <h2 className="mb-1 clr-303778">Provincial Electricity Authority</h2>
                        <p className="mb-1">200 Ngam Wong Wan Rd., Lat yao, Chatuchak Bangkok 10900</p>
                        {/* <p className="mb-1">(123) 456 -829</p> */}
                        <p className="mb-1">(Tax ID No.) 09940001655</p>
                    </div>
                </div>
            </div>
            <div className="w-100 text-center">
                <h1 className="clr-303778 mb-1">Quatation</h1>                            
            </div>
            <div className="d-flex w-100 px-4 py-3">
                <div className="w-50">
                    <div>                                    
                        <h2 className="mb-1">{user?.user?.name}</h2>
                        <p className="mb-1">{user?.user?.address}</p>
                        <p className="mb-1">{user?.user?.email}</p>
                    </div>
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <p className="mb-1 mt-2">Date: {currentDate()}</p>
                    </div>
                </div>
            </div>
            <div className="w-100 px-4">
                <table className="pdf-table1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-303778 w-60px">01</td>
                            <td>
                                <h6 className="mb-1">Fund Request</h6>
                                {/* <p className="mb-0">Fund request for staff</p> */}
                            </td>
                            <td className="bg-303778 w-100px">${amount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-flex w-100 px-4 py-3 invoice-header">
                <div className="w-65">
                    {/* <h3 className="pt-4">Thank You</h3> */}
                </div>
                <div className="w-35">
                    <div>
                        <table className="pdf-table2">
                            <thead>
                                <tr className="bb-rgb-237">
                                    <th>SUBTOTAL</th>
                                    <th>${(amount - (amount * 0.07).toFixed(2))}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bb-303778">
                                    <th className="clr-303778">TAX 7%</th>
                                    <th className="clr-303778">${(amount * 0.07)?.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>GRAND TOTAL</th>
                                    <th>${(parseFloat(amount))?.toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>            
        </div>
    )
}); 

const ComponentToPrintForBill = React.forwardRef(({amount}, ref) => {
    const [user, setUser] = useState(null);

    const {id} = useParams();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/view-request/${id}`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Fund request detail : ", res);
            // setData(res?.data?.fundView);
            setUser(res?.data?.fundView);
            // setLoading(false);
        }).catch((err) => {
            // setLoading(false);
            console.log("Fund request detail error : ", err);
        })
    }, [])

    return (
        <div className="w-100" ref={ref}>
            <div className="d-flex w-100 invoice-header px-4 py-3 pt-4">
                <div className="w-50">
                    <img src={logo} height={"125px"} alt="logo" />
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <h2 className="mb-1 clr-303778">Provincial Electricity Authority</h2>
                        <p className="mb-1">200 Ngam Wong Wan Rd., Lat yao, Chatuchak Bangkok 10900</p>
                        {/* <p className="mb-1">(123) 456 -829</p> */}
                        <p className="mb-1">(Tax ID No.) 09940001655</p>
                    </div>
                </div>
            </div>
            <div className="w-100 text-center ">
                <h1 className="clr-303778x mb-1 text-danger">Bill</h1>                            
            </div>
            <div className="d-flex w-100 px-4 py-3">
                <div className="w-50">
                    <div>                                    
                        <h2 className="mb-1">{user?.user?.name}</h2>
                        <p className="mb-1">{user?.user?.address}</p>
                        <p className="mb-1">{user?.user?.email}</p>
                    </div>
                </div>
                <div className="w-50">
                    <div className="text-end">
                        <p className="mb-1 mt-2">Date: {currentDate()}</p>
                    </div>
                </div>
            </div>
            <div className="w-100 px-4">
                <table className="pdf-table1">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Description</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="bg-303778 w-60px">01</td>
                            <td>
                                <h6 className="mb-1">Fund Request</h6>
                                {/* <p className="mb-0">Fund request for staff</p> */}
                            </td>
                            <td className="bg-303778 w-100px">${amount}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="d-flex w-100 px-4 py-3">
                <div className="w-65">
                    {/* <h3 className="pt-4">Thank You</h3> */}
                </div>
                <div className="w-35">
                    <div>
                        <table className="pdf-table2">
                            <thead>
                                <tr className="bb-rgb-237">
                                    <th>SUBTOTAL</th>
                                    <th>${(amount - (amount * 0.07)).toFixed(2)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bb-303778">
                                    <th className="clr-303778">TAX 7%</th>
                                    <th className="clr-303778">${(amount * 0.07)?.toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th>GRAND TOTAL</th>
                                    <th>${(parseFloat(amount))?.toFixed(2)}</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}); 














// import React from "react";


// const FundRequestDetail = () => {
//     return (
//         <div className="staff-detail-page">
//             <h1 className="staffdetail-t1">#q5d9fcf7</h1>
//             <h6 className="staffdetail-t2"> Fund request <span className="greater-than"> &#62; </span> <span className="staffdetail-t3">#q5d9fcf7</span></h6>
//             <h3 className="staffdetail-sub-heading mt-3 mb-0">Fund request details</h3>
//             <div className="d-flex mt-4 pt-2">
//                 <div className="div1">
//                     <p className="mb-0">Fund request</p>
//                     <p className="mb-0">Date</p>
//                     <p className="mb-0">Time</p>
//                     <p className="mb-0">Transfer</p>
//                     <p className="mb-0">Status</p>
//                     {/* <p className="mb-0">Email</p>
//                     <p className="mb-0">Phone</p>
//                     <p className="mb-0">V-Card</p>
//                     <p className="mb-0">V-Card status</p> */}
//                 </div>
//                 <div className="div2">
//                     <p className="mb-0">2,000,000</p>
//                     <p className="mb-0">18-09-2023</p>
//                     <p className="mb-0">18:30</p>
//                     <p className="mb-0">QR Code</p>
//                     <p className="mb-0 color-42c78f">Active</p>
//                     {/* <p className="mb-0">angleria_2539@gmail.com</p>
//                     <p className="mb-0">0889563324</p>
//                     <p className="mb-0">122546969998</p> */}
//                     {/* <p className="mb-0">
//                         <span className="color-42c78f">Verify</span>
//                         <button className="btn-2 ms-4">Re-verify</button>
//                     </p> */}
//                 </div>
//             </div>
            
//             <h3 className="staffdetail-sub-heading mt-5">Download QT/Bill</h3>
//             <div className="mt-4">
//                 <button className="btn-3">Quatation</button>
//                 <button className="btn-3 btn-5 ms-3">Bill</button>

//             </div>

//             <h3 className="staffdetail-sub-heading mt-5 mb-0">User Detail</h3>
//             <div className="d-flex mt-4 pt-2">
//                 <div className="div1">
//                     <p className="mb-0">User account</p>
//                     <p className="mb-0">Status</p>
//                     <p className="mb-0">Wallet</p>
//                     <p className="mb-0">Name - Surname</p>
//                     <p className="mb-0">Address</p>
//                     <p className="mb-0">Email</p>
//                     <p className="mb-0">Phone</p>
//                     <p className="mb-0">Company</p>
//                 </div>
//                 <div className="div2">
//                     <p className="mb-0">Kerry</p>
//                     <p className="mb-0 color-42c78f">Active</p>
//                     <p className="mb-0">2,000</p>
//                     <p className="mb-0">John Wick</p>
//                     <p className="mb-0">1663/404 Pattanakan Road Suanluang Suanluang Bangkok 10250</p>
//                     <p className="mb-0">design@trigongroup.net</p>
//                     <p className="mb-0">0892244956</p>
//                     <p className="mb-0">Kerry Express</p>
//                 </div>
//             </div>
            
//             <h3 className="staffdetail-sub-heading mt-5 mb-0">Bill Information</h3>
//             <div className="d-flex mt-4 pt-2">
//                 <div className="div1">
//                     <p className="mb-0">Company</p>
//                     <p className="mb-0">Company Address</p>
//                     <p className="mb-0">Phone</p>
//                     <p className="mb-0">TAX ID</p>
//                 </div>
//                 <div className="div2">
//                     <p className="mb-0">Kerry Express</p>
//                     <p className="mb-0">2,000</p>
//                     <p className="mb-0">1111/105 Baangrangmuang Ladpao Road Junkasem Jatujak Bangkok 10900</p>
//                     <p className="mb-0">025788877</p>
//                 </div>
//             </div>

//             {/* <div className="mb-5 pt-4">
//                 <button className="btn-3 mt-4">Edit</button>
//             </div> */}
//         </div>
//     )
// }

// export default FundRequestDetail;