import React from "react";
import logo from "../../../asserts/logo.png";

const ResetPassword = () => {

    return ( 
        <div className="login-page">
            <div className="content-div">
                <div className="text-center">
                    <img src={logo} className="logo" alt="logo" />
                </div>
                <h1 className="forget-t1 mt-3 mb-0">Reset your password</h1>
                <div className="mt-4">
                    <div className="w-100-50 margin-auto">
                        <input type="text" className="form-control w-100" placeholder="New Password" />
                    </div>
                </div>
                <div className="d-flex mt-4">
                    <div className="w-100-50 margin-auto">
                        <input type="text" className="form-control w-100" placeholder="Confirm Password" />
                    </div>
                </div>
                <div className="d-flex mt-4">
                    <div className="w-100-50 margin-auto">
                        <button className="login-btn">CONFIRM</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;