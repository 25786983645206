import logo from './logo.svg';
import './App.css';
import { Route, Routes, useNavigate } from 'react-router-dom';
import User from './components/user/User';
import Login from './components/user/pages/Login';
import ForgetPassword from './components/user/pages/ForgetPassword';
import ResetPassword from './components/user/pages/ResetPassword';
import Overview from './components/user/pages/Overview';
import ViewUser from './components/user/pages/ViewUser';
import EditUser from './components/user/pages/EditUser';
import Setting from './components/user/pages/Setting';
import EditProfile from './components/user/pages/EditProfile';
import FundRequest from './components/user/pages/FundRequest';
import AddFundRequest from './components/user/pages/AddFundRequest';
import TransferOption from './components/user/pages/TransferOption';
import QRCode from './components/user/pages/QRCode';
import BankdTransfer from './components/user/pages/BankTransfer';
import CardSuccess from './components/user/pages/CardSuccess';
import Report from './components/user/pages/Report';
import FundRequestDetail from './components/user/pages/FundRequestDetail';
import StaffReport from './components/user/pages/StaffReport';
import { useEffect } from 'react';
import UserList from './components/user/pages/UserList';
import AddUser from './components/user/pages/AddUser';
import UserStatement from './components/user/pages/UserStatement';
import EditFund from './components/user/pages/EditFund';
import UserReport from './components/user/pages/UserReport';
import ListStaff from './components/user/pages/ListStaff';
import ViewStaff from './components/user/pages/ViewStaff';
import BuyPointsStatement from './components/user/pages/BuyPointsStatement';
import StaffToppingReport from './components/user/pages/StaffToppingReport';
import TransferReport from './components/user/pages/TransferReport';
import ChargingReport from './components/user/pages/ChargingReport';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("admin_token");
    // console.log("token : ", token)
    if(!token){
      navigate('/login');
    }  
  }, [])
  
  return (
    <>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/forget-password' element={<ForgetPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path="/" element={<User />} >
          <Route index element={<Overview />} />
          <Route path="users" element={<UserList />} />
          <Route path="view-user/:id" element={<ViewUser />} />
          <Route path="user/edit/:id" element={<EditUser />} /> 
          <Route path="user/add" element={<AddUser />} />
          <Route path="user/statement/:name/:id" element={<UserStatement />} />
          <Route path="user/edit-fund/:name/:id" element={<EditFund />} />
          <Route path="user/report/:name/:id" element={<UserReport />} />
          <Route path="user/staff-list/:name/:id" element={<ListStaff />} />
          <Route path="user/staff-list/:name/:id/view-staff/:vcard/:sid" element={<ViewStaff />} />
          <Route path="user/staff-list/:name/:id/view-staff/report/:vcard/:sid" element={<StaffReport />} />
          <Route path="user/staff-list/:name/:id/view-staff/topping-report/:vcard/:sid" element={<StaffToppingReport />} />
          <Route path="user/buypointsstatement/:name/:id" element={<BuyPointsStatement />} />
          <Route path="setting" element={<Setting />} />
          <Route path="setting/edit/:id" element={<EditProfile />} />
          <Route path="fund-requests" element={<FundRequest />} />
          <Route path="fund-requests/add" element={<AddFundRequest />} />
          <Route path="fund-requests/add/transfer" element={<TransferOption />} />
          <Route path="fund-requests/add/transfer/qr-code" element={<QRCode />} />
          <Route path="fund-requests/add/transfer/bank-transfer" element={<BankdTransfer />} />
          <Route path="fund-requests/add/transfer/card" element={<CardSuccess />} />
          <Route path='report' element={<Report />} />
          <Route path='fund-request/detail/:id' element={<FundRequestDetail />} />
          <Route path='staff-report/:id' element={<StaffReport />} />
          <Route path='transfer-report' element={<TransferReport />} />
          <Route path='charging-report' element={<ChargingReport />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
