import React from "react";
import { useNavigate } from "react-router-dom";


const CardSuccess = () => {
    const navigate = useNavigate();

    return (
        <div className="staff-detail-page edit-profile1">
            <h1 className="staffdetail-t1">Create new quatation</h1>
            <h6 className="staffdetail-t2"> 
                Fund request 
                <span className="greater-than"> &#62; </span>
                Create new quatation 
                <span className="greater-than"> &#62; </span>
                Transfer
                <span className="greater-than"> &#62; </span>
                <span className="staffdetail-t3">Card</span>
            </h6>
            
            <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">Card / Success</h3>
            <p className="card-success-t1 mt-3">Success.</p>
            
            
            <div className="mb-5 pt-4">
                <button className="btn-2 me-4" onClick={() => navigate('/fund-requests')} style={{width: '200px'}}>Go to Fund request</button>
            </div>
        </div>
    )
}

export default CardSuccess;