import React, { useState } from "react";
// import logo from "../../asserts/sidebar-logo.png"
import logo from "../../asserts/new-logo.jpeg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../utility";

const Sidebar = () => {
    const [tog, setTog] = useState(1);
    const navigate = useNavigate();

    const logoutFun = () => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/logout`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("profile res : ", res);
            // setStaffList(res?.data?.users);
        }).catch((err) => {
            console.log("profile error : ", err);
        })
        localStorage.removeItem("admin_token");
        navigate('/login');
    }

    return (
        <div className="sidebar-wrapper">
            <div className="sidebar">
                <div className="ps-3 pt-3">
                    <img src={logo} className="logo" width={"75%"} alt="logo" />
                </div>
                <div>
                    <ul className="sidbar-ul">
                        <li className={localStorage.getItem("ba_tab") === "1" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "1"); setTog(1); navigate('/');}}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.753" height="26.247"><path d="M16.429 6.81 5.626 15.706v9.603c0 .517.42.937.937.937l6.566-.017a.937.937 0 0 0 .933-.937v-5.608c0-.518.42-.938.938-.938h3.75c.517 0 .937.42.937.938v5.603a.938.938 0 0 0 .938.94l6.563.02c.518 0 .938-.42.938-.938V15.7l-10.8-8.89a.714.714 0 0 0-.897 0Zm17.064 6.047-4.898-4.038V.703A.703.703 0 0 0 27.892 0H24.61a.703.703 0 0 0-.703.703v4.255L18.661.642a2.813 2.813 0 0 0-3.574 0L.255 12.857a.703.703 0 0 0-.094.99l1.495 1.816a.703.703 0 0 0 .99.096L16.43 4.407a.714.714 0 0 1 .896 0l13.783 11.352c.3.247.743.205.99-.094l1.495-1.816a.703.703 0 0 0-.1-.992Z" fill-rule="evenodd" data-name="Icon awesome-home"/></svg>
                            </b>
                            <span>Overview</span>
                        </li>
                        <li className={localStorage.getItem("ba_tab") === "2" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "2"); setTog(2); navigate('/users'); }}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.063" height="29.063"><path d="M14.531 0C6.504 0 0 6.504 0 14.531c0 8.028 6.504 14.531 14.531 14.531 8.028 0 14.531-6.503 14.531-14.53C29.063 6.503 22.56 0 14.533 0Zm0 5.625a5.156 5.156 0 1 1 0 10.313 5.156 5.156 0 0 1 0-10.313Zm0 20.156c-3.44 0-6.521-1.558-8.584-3.996 1.102-2.074 3.258-3.504 5.772-3.504.14 0 .281.024.416.065.761.246 1.558.404 2.396.404.838 0 1.64-.158 2.397-.404.134-.041.275-.065.416-.065 2.513 0 4.67 1.43 5.771 3.504-2.062 2.438-5.144 3.996-8.584 3.996Z" fill-rule="evenodd" data-name="Icon awesome-user-circle"/></svg>
                            </b>
                            <span>User</span>
                        </li>
                        <li className={localStorage.getItem("ba_tab") === "3" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "3"); setTog(3); navigate('/fund-requests')}}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25"><path d="M15 0C6.716 0 0 5.456 0 12.188c0 2.903 1.254 5.566 3.341 7.66-.736 2.948-3.183 5.58-3.212 5.611a.468.468 0 0 0 .34.791c3.882 0 6.796-1.86 8.238-3.01A17.851 17.851 0 0 0 15 24.374c8.284 0 15-5.456 15-12.188C30 5.457 23.284 0 15 0Zm1.406 17.721v1.029c0 .518-.42.938-.937.938h-.938a.937.937 0 0 1-.937-.938v-1.039a4.28 4.28 0 0 1-1.862-.671c-.365-.241-.4-.769-.091-1.077l1.026-1.027c.22-.219.546-.248.827-.119.186.086.39.13.601.13h1.921a.494.494 0 0 0 .136-.968l-2.934-.838c-1.304-.372-2.344-1.448-2.514-2.793a3.297 3.297 0 0 1 2.89-3.695V5.625c0-.518.42-.938.937-.938h.938c.518 0 .937.42.937.938v1.039a4.28 4.28 0 0 1 1.862.671c.365.241.4.769.091 1.077L17.333 9.44c-.22.219-.546.248-.827.119a1.436 1.436 0 0 0-.601-.13h-1.921a.494.494 0 0 0-.136.968l2.934.838c1.304.373 2.344 1.448 2.514 2.793.237 1.879-1.115 3.482-2.89 3.694Z"  fill-rule="evenodd" data-name="Icon awesome-comment-dollar"/></svg>
                            </b>
                            <span>Fund List</span>
                        </li>
                        {/* <li className={localStorage.getItem("ba_tab") === "4" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "4"); setTog(4); navigate('/report');}}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.688" height="26.25"><path d="M17.227 3.281h-4.102A3.284 3.284 0 0 0 9.844 0a3.284 3.284 0 0 0-3.281 3.281H2.46A2.462 2.462 0 0 0 0 5.742V23.79a2.462 2.462 0 0 0 2.46 2.461h14.767a2.462 2.462 0 0 0 2.46-2.46V5.741a2.462 2.462 0 0 0-2.46-2.46ZM4.922 21.738c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.922c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.921c-.682 0-1.23-.55-1.23-1.23 0-.683.548-1.231 1.23-1.231.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23ZM9.844 2.05c.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23Zm6.562 18.867c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Z" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            </b>
                            <span>Report</span>
                        </li> */}

                        <li className={localStorage.getItem("ba_tab") === "5" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "5"); setTog(4); navigate('/transfer-report');}}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.688" height="26.25"><path d="M17.227 3.281h-4.102A3.284 3.284 0 0 0 9.844 0a3.284 3.284 0 0 0-3.281 3.281H2.46A2.462 2.462 0 0 0 0 5.742V23.79a2.462 2.462 0 0 0 2.46 2.461h14.767a2.462 2.462 0 0 0 2.46-2.46V5.741a2.462 2.462 0 0 0-2.46-2.46ZM4.922 21.738c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.922c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.921c-.682 0-1.23-.55-1.23-1.23 0-.683.548-1.231 1.23-1.231.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23ZM9.844 2.05c.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23Zm6.562 18.867c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Z" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            </b>
                            <span>Transfer Report</span>
                        </li>
                        <li className={localStorage.getItem("ba_tab") === "6" ? "active" : "cursor-pointer"} onClick={() => { localStorage.setItem("ba_tab", "6"); setTog(4); navigate('/charging-report');}}>
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="29.688" height="26.25"><path d="M17.227 3.281h-4.102A3.284 3.284 0 0 0 9.844 0a3.284 3.284 0 0 0-3.281 3.281H2.46A2.462 2.462 0 0 0 0 5.742V23.79a2.462 2.462 0 0 0 2.46 2.461h14.767a2.462 2.462 0 0 0 2.46-2.46V5.741a2.462 2.462 0 0 0-2.46-2.46ZM4.922 21.738c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.922c-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23Zm0-4.921c-.682 0-1.23-.55-1.23-1.23 0-.683.548-1.231 1.23-1.231.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23ZM9.844 2.05c.682 0 1.23.548 1.23 1.23 0 .682-.548 1.23-1.23 1.23-.682 0-1.23-.548-1.23-1.23 0-.682.548-1.23 1.23-1.23Zm6.562 18.867c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Zm0-4.922c0 .226-.184.41-.41.41H8.613a.411.411 0 0 1-.41-.41v-.82c0-.226.185-.41.41-.41h7.383c.226 0 .41.184.41.41v.82Z" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                            </b>
                            <span>Charging Report</span>
                        </li>
                    </ul>
                </div>
                <div className="sidbar-bottom-div">
                    <ul className="sidbar-ul">
                        {/* <li className={tog === 5 ? "active" : "cursor-pointer"} onClick={() => { setTog(5); navigate('/setting')}}>
                            <b>
                               <svg xmlns="http://www.w3.org/2000/svg" width="24.375" height="24.375"><path d="M21.444 13.406c.062-.365.062-.792.062-1.219 0-.426-.062-.792-.062-1.218l2.617-2.011c.25-.183.311-.487.125-.792L21.692 3.96c-.124-.244-.498-.365-.748-.244l-3.116 1.219a9.072 9.072 0 0 0-2.119-1.22L15.273.488A.668.668 0 0 0 14.65 0H9.663a.668.668 0 0 0-.623.487l-.498 3.23c-.749.305-1.434.731-2.12 1.219l-3.116-1.22c-.312-.12-.624 0-.748.245L.065 8.166c-.125.243-.063.61.124.792l2.68 2.01c0 .427-.062.793-.062 1.22 0 .426.062.792.062 1.218L.252 15.417c-.25.183-.311.487-.125.792l2.494 4.205c.124.244.498.365.748.244l3.116-1.219a9.07 9.07 0 0 0 2.119 1.219l.498 3.23c.063.304.312.487.624.487h4.986a.668.668 0 0 0 .623-.488l.5-3.229c.747-.305 1.433-.731 2.118-1.219l3.116 1.219c.312.121.624 0 .748-.244l2.494-4.205c.124-.243.062-.61-.125-.792l-2.742-2.01Zm-9.288 3.047c-2.43 0-4.362-1.889-4.362-4.266 0-2.376 1.931-4.265 4.362-4.265s4.363 1.889 4.363 4.265c0 2.377-1.932 4.266-4.363 4.266Z" fill-rule="evenodd" data-name="Icon ionic-md-settings"/></svg>
                            </b>
                            <span>Setting</span>
                        </li> */}
                        <li onClick={logoutFun} className="pointer">
                            <b>
                                <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5"><g data-name="Icon feather-log-out"><path d="M7.5 22.5h-5A2.5 2.5 0 0 1 0 20V2.5A2.5 2.5 0 0 1 2.5 0h5" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#7c7f84" fill="transparent" data-name="Path 18"/><path d="m16.25 17.5 6.25-6.25L16.25 5" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#7c7f84" fill="transparent" data-name="Path 19"/><path d="M22.5 11.25h-15" stroke-linejoin="round" stroke-linecap="round" stroke-width="2.5" stroke="#7c7f84" fill="transparent" data-name="Path 20"/></g></svg>
                            </b>
                            <span >Logout</span>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;