import React, { useState } from "react";
import { API_URL, generateRandomPassword } from "../../../utility";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../../common/Loader";

const Msg = ({ closeToast, toastProps }) => (
    <div>
      Lorem ipsum dolor {toastProps.position}
      <button>Retry</button>
      <button onClick={closeToast}>Close</button>
    </div>
);

const AddUser = () => { 
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, reset, watch, formState: { errors }, } = useForm();
    const navigate = useNavigate();

    
    
    const onSubmit = (data) => {
        setLoading(true);
        console.log("data : ", data);
        const token = localStorage.getItem("admin_token");
        axios.post(`${API_URL}/admin/users/add`, data, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Add user res : ", res);
            // localStorage.setItem("admin_token", res?.data?.token);
            setLoading(false);
            if(res?.data?.user){
                reset({});
                toast.success("User Created Successfully");
                setTimeout(() => {
                    navigate('/users');
                }, 3000)
            } else {
                toast.error("Failed to create user!");
            }
        }).catch((err) => {
            setLoading(false);
            if(err?.response?.data?.type === "API"){
                toast.error(err?.response?.data?.error);
                toast.error(err?.response?.data?.from);
            } else {
                toast.error(err?.response?.data?.error?.name ? err?.response?.data?.error?.name[0] : err?.response?.data?.error?.email ? err?.response?.data?.error?.email[0] : err?.response?.data?.error?.address ? err?.response?.data?.error?.address[0] : err?.response?.data?.error?.phone_number ? err?.response?.data?.error?.phone_number[0] : err?.response?.data?.error?.company ? err?.response?.data?.error?.company[0] : err?.response?.data?.error?.password ? err?.response?.data?.error?.password[0] : err?.response?.data?.error);
            }
            console.log("Add User Error : ", err);
            // setError(err?.response?.data?.message);
        })
        
    }

    const notify = () => toast.info("Wow so easy !");

    const displayMsg = () => {
        toast(<Msg />);
        // toast(Msg) would also work
      };

    return (
        <div className="staff-detail-page">
            {/* <button onClick={notify}>Notify !</button> */}
            {/* <button onClick={displayMsg}>Click me</button> */}
            <ToastContainer />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="staffdetail-t1">Add User</h1>
            {loading ? (<Loader />) : (
                <>
                        {/* <h6 className="staffdetail-t2"> 
                            Staff 
                            <span className="greater-than"> &#62; </span> 
                            <span className="staffdetail-t3">Add Staff</span>
                        </h6> */}
                        <h3 className="staffdetail-sub-heading mt-4 mb-0">Information</h3>
                        <div className="adf">
                            <p className="mb-1 mt-3">
                                User account 
                            </p>
                            <input type="text" {...register("user_account", {required: true})} className="form-control w-75"  />
                            {errors?.user_account && (<p className="text-danger mt-1">User account is required.</p>)}
                        </div>
                        <div className="adf d-flex w-75">
                            <div className="w-50 pe-2">
                                <p className="mb-1 mt-3">Name <span className="text-danger">*</span></p>
                                <input type="text" 
                                {...register("name", {required: true})} 
                                className="form-control w-100"  />
                                {errors?.name && (<p className="text-danger mt-1">Name is required.</p>)}
                            </div>
                            <div className="w-50 ps-2">
                                <p className="mb-1 mt-3">Surename <span className="text-danger">*</span></p>
                                <input type="text" 
                                {...register("surename", {required: true})} 
                                className="form-control w-100"  />
                                {errors?.surename && (<p className="mt-1 text-danger">Surename is required.</p>)}
                            </div>
                        </div>
                        <div className="adf">
                            <p className="mb-1 mt-3">
                                Address <span className="text-danger">*</span>
                            </p>
                            <input type="text" 
                            {...register("address", {required: true})} 
                            className="form-control w-75"  />
                            {errors?.address && (<p className="text-danger mt-1">Address is required.</p>)}
                        </div>
                        <div className="adf d-flex w-75">
                            <div className="w-50 pe-2">
                                <p className="mb-1 mt-3">Email <span className="text-danger">*</span></p>
                                <input type="text" 
                                {...register("email", {required: true})} 
                                className="form-control w-100"  />
                                {errors?.email && (<p className="text-danger mt-1">Email is required</p>)}
                            </div>
                            <div className="w-50 ps-2">
                                <p className="mb-1 mt-3">Phone <span className="text-danger">*</span></p>
                                <input type="text" 
                                {...register("phone_number", {required: true})} 
                                className="form-control w-100"  />
                                {errors?.phone_number && (<p className="text-danger mt-1">Phone number is required.</p>)}
                            </div>
                        </div>
                        <div className="adf">
                            <p className="mb-1 mt-3">
                                Company <span className="text-danger">*</span>
                            </p>
                            <input type="text" 
                            {...register("company", {required: true})} 
                            className="form-control w-75"  />
                            {errors?.company && (<p className="mt-1 text-danger">Company is required.</p>)}
                        </div>

                        <div className="adf d-flex w-75">
                            <div className="w-50 pe-2">
                                <p className="mb-1 mt-3">Password <span className="text-danger">*</span></p>
                                <div className="p-relative">
                                    <input type={showPassword ? "text" : "password"} {...register("password", {required: true})} className="form-control w-100"  />
                                    <svg onClick={() => setShowPassword(!showPassword)} className="eye-icon-gen hover" xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="740 700.75 33.75 22.5"><path d="M773.546 711.145c-3.178-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.67 10.395 7.204 0 13.496-4.198 16.672-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.437-8.438 8.433 8.433 0 0 1-8.437 8.438Zm0-14.063c-.502.007-1.001.082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                                </div>
                                {errors?.password && (<p className="text-danger mt-1">Password is required.</p>)}
                            </div>
                            <div className="w-50 pt-3">
                                <div className="mt-1">
                                    <button className="btn-4 mt-4" type="button" onClick={() => { reset({password: generateRandomPassword(9)})}}>Generate</button>
                                </div>
                            </div>
                        </div>

                        {/* <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">Information <span className="AddUser-t1">(Auto fill)</span></h3>            
                        <div className="d-flex mt-4 pt-2">
                            <div className="div1">
                                <p className="mb-0">Name - Surname</p>
                                <p className="mb-0">Address</p>
                                <p className="mb-0">Email</p>
                                <p className="mb-0">Phone</p>
                            </div>
                            <div className="div3">
                                <p className="mb-0 ">
                                    <div className='switch-btn-div pt-31' style={{position: 'relative', top: '-4px'}}>
                                        <input type="checkbox" defaultChecked={true}  name="check" value={true} class="toggle-station"/>                            
                                    <span className="switch-active-label">Active</span>
                                    </div>
                                </p>
                                <p className="mb-0">Angleria Horgan</p>
                                <p className="mb-0">6658 Ladpao Road Jungrasem Jatujak Bangkok 10900</p>
                                <p className="mb-0">angleria_2539@gmail.com</p>
                                <p className="mb-0">0889563324</p>
                                
                            </div>
                        </div> */}
                        <div className="mb-5 pt-4">
                            <button className="btn-3 mt-4">Submit</button>
                            <button className="btn-2 ms-4">Reset</button>
                        </div>
                        </>
                    )}
                    </form>
        </div>
    )
}

export default AddUser;