import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, generateRandomPassword } from "../../../utility";
import { useForm } from "react-hook-form";
import Loader from "../../common/Loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SweetAlert from "react-bootstrap-sweetalert";
import { MdOutlineContentCopy } from "react-icons/md";

const EditUser = () => {
    const [isChecked, setIsChecked] = useState(true);

    const { register, handleSubmit, reset, watch, formState: { errors }, } = useForm();

    const { id } = useParams();

    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [flag, setFlag] = useState(2);

    const [loading, setLoading] = useState(true);

    const [showPassword, setShowPassword] = useState(false);
    const [show, setShow] = useState(false);
    
    
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users/${id}`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("View User res : ", res);
            setUser(res?.data?.user);
            const resObj = res?.data?.user;
            const resObj2 = res?.data;
            setIsChecked(resObj?.status === 1 ? true : false);
            reset({...resObj, password: resObj?.passtr, company_name: resObj2?.billInfo?.company_name, company_phone: resObj2?.billInfo?.company_phone, company_address: resObj2?.billInfo?.company_address, company_tax_id: resObj2?.billInfo?.company_tax_id});
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("View User error : ", err);
        })
    }, [flag])
    
    const onSubmit = (data) => {
        setLoading(true);
        console.log("data : ", data);
        const token = localStorage.getItem("admin_token");
        axios.post(`${API_URL}/admin/users/${id}/edit`, {...data, user_account: user?.user_account, status: isChecked? "1" : "0"}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("User update res : ", res);
            // localStorage.setItem("admin_token", res?.data?.token);
            setLoading(false);
            toast.success("User updated successfully");
            setTimeout(() => {
                navigate('/users');
            }, 6000)
        }).catch((err) => {
            console.log("User update Error : ", err);
            // if(err?.response?.status === 500){
            //     toast.error("Server Error");
            // }
            if(err?.response?.data?.type === "API"){
                toast.error(err?.response?.data?.error);
                toast.error(err?.response?.data?.from);
            } else {
                toast.error(err?.response?.data?.error?.name ? err?.response?.data?.error?.name[0] : err?.response?.data?.error?.email ? err?.response?.data?.error?.email[0] : err?.response?.data?.error?.address ? err?.response?.data?.error?.address[0] : err?.response?.data?.error?.phone_number ? err?.response?.data?.error?.phone_number[0] : err?.response?.data?.error?.company ? err?.response?.data?.error?.company[0] : err?.response?.data?.error?.password ? err?.response?.data?.error?.password[0] : "Server Error");
            }
            setLoading(false);
            // setError(err?.response?.data?.message);
        })
    }

    const copyPassword = () => {
        // user?.passtr?.select();
        // user?.passtr?.setSelectionRange(0, 99999); // For mobile devices

        // Copy the text inside the text field
        navigator.clipboard.writeText(watch("password"));
        toast.success("Password copied to clipboard successfully");
    }

    // if(loading){
    //     return (
    //         <Loader />
    //     )
    // } else {
        return (
            <div className="staff-detail-page">
                {show && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Yes Sure!"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="secondary"
                        title="Are you sure?"
                        onConfirm={() => {reset({password: generateRandomPassword(9)}); setShow(false)}}
                        onCancel={() => {setShow(false)}}
                        focusCancelBtn
                    >
                        You want to generate password
                    </SweetAlert>
                )}
                <ToastContainer />
                {loading ? (<Loader />) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h1 className="staffdetail-t1">{user?.user_account}</h1>
                            <h6 className="staffdetail-t2">
                                <span onClick={() => navigate('/users')} className="pointer">
                                    User 
                                </span> 
                                <span className="greater-than"> &#62; </span> 
                                {user?.user_account} 
                                <span className="greater-than"> &#62; </span> 
                                <span className="staffdetail-t3">Edit User</span>
                            </h6>
                            <h3 className="staffdetail-sub-heading mt-3 mb-0">Information</h3>
                            <div className="d-flex mt-4 pt-2">
                                <div className="div1">
                                    <p className="mb-0 h-45">Status</p>
                                    <p className="mb-0 h-45">User account</p>
                                    <p className="mb-0 h-45">Name</p>
                                    <p className="mb-0 h-45">Address</p>
                                    <p className="mb-0 h-45">Email</p>
                                    <p className="mb-0 h-45">Phone</p>
                                    <p className="mb-0 h-45">Company</p>
                                </div>
                                <div className="div3">
                                    <p className="mb-0 h-45">
                                        <div className='switch-btn-div pt-31' style={{position: 'relative', top: '-4px'}}>
                                            <input id="switch-status" type="checkbox" onChange={(e) => {setIsChecked(e.target.checked); console.log("value : ", e.target.checked)}} defaultChecked={isChecked}  name="check" value={true} class="toggle-station"/>                            
                                        <label for="switch-status" className={isChecked ? "switch-active-label" : "switch-active-label text-dark swith-off"}>{isChecked? 'Enable' : 'Disable'}</label>
                                        </div>
                                    </p>
                                    <p className="mb-0 text-bold h-45">{user?.user_account}</p>
                                    <div className="d-flex w-75 h-45">
                                        <div className="me-3" style={{width: 'calc(50% - 55px)'}}>
                                            <input type="text" 
                                            {...register("name", {required: true})} 
                                            className="form-control w-100"  />
                                        </div>
                                        <div className="w-110">
                                            <p className="mb-0 mt-2">Surname</p>
                                        </div>
                                        <div className="ms-3" style={{width: 'calc(50% - 55px)'}}>
                                            <input type="text" {...register("surename", {required: true})} className="form-control w-100"  />
                                        </div>
                                    </div>
                                    <p className="mb-0 h-45">
                                        <input type="text" {...register("address", {required: true})} className="form-control w-75"  />
                                    </p>
                                    <p className="mb-0 h-45">
                                        <input type="text" readOnly {...register("email", {required: true})} className="form-control w-75"  />
                                    </p>
                                    <p className="mb-0 h-45">
                                        <input type="text" {...register("phone_number", {required: true})} className="form-control w-75"  />
                                    </p>
                                    {/* <p className="mb-0 h-45">6658 Ladpao Road Jungrasem Jatujak Bangkok 10900</p>
                                    <p className="mb-0 h-45">angleria_2539@gmail.com</p>
                                    <p className="mb-0 h-45">0889563324</p> */}
                                    {/* <p className="mb-0 h-45"></p> */}
                                    <p className="mb-0 h-45">
                                        <input type="text" {...register("company", {required: true})} className="form-control w-75"  />
                                    </p>
                                </div>
                            </div>
                            <h3 className="staffdetail-sub-heading mt-3 mb-0">Reset Password</h3>
                            <div className="adf d-flex w-75 mt-3">
                                <div className="w-50 pe-2">
                                    {/* <p className="mb-1 mt-3">Password <span className="text-danger">*</span></p> */}
                                    <div className="p-relative">
                                        <input {...register("password")} type={showPassword ? "text" : "password"} className="form-control w-100"  />
                                        <svg onClick={() => setShowPassword(!showPassword)} className="eye-icon-gen pointer" cla xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="740 700.75 33.75 22.5"><path d="M773.546 711.145c-3.178-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.67 10.395 7.204 0 13.496-4.198 16.672-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.437-8.438 8.433 8.433 0 0 1-8.437 8.438Zm0-14.063c-.502.007-1.001.082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                                        <MdOutlineContentCopy color="#d2d2d2" onClick={copyPassword} className="copy-icon" size={"0.55cm"} />
                                    </div>
                                </div>
                                <div className="w-50 pt-3x">
                                    <div className="mt-1x">
                                        <button type="button" onClick={() => { setShow(true); }} className="btn-4 mt-4x">Generate</button>
                                    </div>
                                </div>
                            </div>

                            <h3 className="staffdetail-sub-heading mt-5 mb-0">Bill Information</h3>
                            <div className="d-flex mt-4 pt-2">                            
                                <div className="div1">
                                    <p className="mb-0 mt-2">Company</p>
                                    <p className="mb-0 mt-2">Company Address</p>
                                    <p className="mb-0 mt-2">Phone</p>
                                    <p className="mb-0 mt-2">TAX ID</p>
                                </div>
                                <div className="div2 w-50">
                                    <p className="mb-0 mt-2">
                                        <input type="text" {...register("company_name")} className="form-control w-50" />                                
                                    </p>
                                    <p className="mb-0 mt-2">
                                        <input {...register("company_address")} type="text" className="form-control w-100" />                                
                                    </p>
                                    <p className="mb-0 mt-2">
                                        <input type="text" {...register("company_phone")} className="form-control w-100" />                                
                                    </p>
                                    <p className="mb-0 mt-2">
                                        <input type="text" {...register("company_tax_id")} className="form-control w-100" />                                
                                    </p>
                                    
                                </div>
                            </div>
                            {/* <div className="d-flex mt-4 pt-2">
                                <div className="div1">
                                    <p className="mb-0 mt-2">Company</p>
                                    <p className="mb-0">Company Address</p>
                                    <p className="mb-0">Phone</p>
                                    <p className="mb-0">TAX ID</p>
                                </div>
                                <div className="div3">
                                    <p className="mb-0 text-bold">Kerry Express</p>
                                    <p className="mb-0 text-bold">1111/105 Baangrangmuang Ladpao Road Junkasem Jatujak Bangkok 10900</p>
                                    <p className="mb-0 text-bold">025788877</p>
                                    <p className="mb-0 text-bold">1233255489966</p>
                                </div>
                            </div> */}

                            <div className="mb-5 pt-4">
                                <button type="submit" className="btn-3 mt-4">Submit</button>
                                <button className="btn-2 ms-4" type="button" onClick={() => navigate('/users')}>Cancel</button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        )
    // }
}

export default EditUser;