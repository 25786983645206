import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../../../utility";


const EditFund = () => {
    const [isChecked, setIsChecked] = useState(false);

    const { id, name } = useParams();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.post(`${API_URL}/admin/users/${id}/edit_fund`, {}, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Edit fund res : ", res);
            // setStaffList(res?.data?.users);
        }).catch((err) => {
            console.log("Edit Fund error : ", err);
        })
    }, [])

    return (
        <div className="staff-detail-page">
            <h1 className="staffdetail-t1">{name}</h1>
            <h6 className="staffdetail-t2"> 
                User 
                <span className="greater-than"> &#62; </span> 
                {name} 
                <span className="greater-than"> &#62; </span> 
                <span className="staffdetail-t3">Extra edit fund</span>
            </h6>
            <h3 className="staffdetail-sub-heading mt-3 mb-0">Extra edit fund</h3>
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0 h-45">Add fund</p>
                    <p className="mb-0 h-45 mt-4">Attach File</p>
                </div>
                <div className="div3">
                    <div className="d-flex w-75 h-45">
                        <div className="me-2" style={{width: 'calc(50% - 55px)'}}>
                            <input type="text" placeholder="1,000,000" className="form-control w-100"  />
                        </div>
                        <div className="w-110">
                            <p className="mb-0 mt-2">THB</p>
                        </div>
                    </div>
                    <div className="d-flex w-75 h-45 mt-4">
                        <div className="me-2 p-relative" style={{width: 'calc(50% - 55px)'}}>
                            <input type="text" placeholder="QT5598745512.PDF" className="form-control w-100"  />
                            <svg className="eye-icon-gen" xmlns="http://www.w3.org/2000/svg" width="19.251" height="22.001" viewBox="923.749 389 19.251 22.001"><path d="m937.367 396.027-1.396-1.395-6.978 6.977a2.96 2.96 0 1 0 4.187 4.187l8.374-8.373a4.934 4.934 0 1 0-6.978-6.977l-8.793 8.791-.018.018a6.88 6.88 0 0 0 0 9.731 6.883 6.883 0 0 0 9.732 0l.018-.019v.001l6.002-6-1.396-1.395-6.002 6-.018.018a4.915 4.915 0 0 1-6.941 0 4.912 4.912 0 0 1 0-6.94l.019-.018-.001-.001 8.794-8.791a2.964 2.964 0 0 1 4.187 0 2.964 2.964 0 0 1 0 4.186l-8.375 8.373a.988.988 0 0 1-1.396-1.395l6.979-6.978Z" fill="#dededf" fill-rule="evenodd" data-name="Icon metro-attachment"/></svg>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mb-5 pt-4">
                <button className="btn-3 mt-4">Accept</button>
                <button className="btn-2 ms-4">Cancel</button>
            </div>
        </div>
    )
}

export default EditFund;