import React, { useState } from "react";
import QRCodeImg from '../../../asserts/pea qr-code.png';
import { useNavigate } from "react-router-dom";

const QRCode = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="staff-detail-page edit-profile1">
            <h1 className="staffdetail-t1">Create new quatation</h1>
            <h6 className="staffdetail-t2"> 
                Fund request 
                <span className="greater-than"> &#62; </span>
                Create new quatation 
                <span className="greater-than"> &#62; </span>
                Transfer
                <span className="greater-than"> &#62; </span>
                <span className="staffdetail-t3">QR Code</span>
            </h6>
            
            <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">QR Code</h3>
            <div className="pt-3 mt-2">
                <img src={QRCodeImg} height={200} alt="qr-code" />
            </div>
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0 bg-secondary1">Bank</p>
                    <p className="mb-0 bg-info1">Name Account</p>
                    <p className="mb-0 bg-primary1">Name Account</p>
                </div>
                <div className="div2 w-75 ms-3">
                      <p className="mb-0 bg-danger1">Kasikorn bank</p>
                      <p className="mb-0 bg-warning1">PEA Account</p>
                      <p className="mb-0 bg-success1">213-558-7556-4</p>
                </div>
            </div>
            
            <div className="mb-5 pt-4">
                <button className="btn-2 me-4">Back</button>
                <button className="btn-3 mt-4" onClick={() => navigate('/fund-requests/add/transfer/card')}>Success</button>
            </div>
        </div>
    )
}

export default QRCode;