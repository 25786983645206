import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDatePicker from "../../common/ReactDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utility";
import Loader from "../../common/Loader";

const ViewUser = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [loading, setLoading] = useState(true);
    const [staffList, setStaffList] = useState([]);

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const { id } = useParams();

    const [user, setUser] = useState(null);
    const [data, setData] = useState(null);
    const [flag, setFlag] = useState(2);
    
    
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users/${id}`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("View User res : ", res);
            setUser(res?.data?.user);
            setData(res?.data?.billInfo);
            setLoading(false);
        }).catch((err) => {
            console.log("View User error : ", err);
            setLoading(false);
        })
    }, [flag])

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users/${id}/staffs`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Staff list res : ", res);
            setStaffList(res?.data?.staffList);
        }).catch((err) => {
            setLoading(false);
            console.log("staff list error : ", err);
        })
    }, [flag])

    if(loading){
        return (
            <Loader />
        )
    } else {
        return (
            <div className="staff-detail-page">
                <h1 className="staffdetail-t1">{user?.user_account}</h1>
                <h6 className="staffdetail-t2 ">
                    <span className="pointer" onClick={() => navigate('/users')}>
                        User 
                    </span> 
                    <span className="greater-than"> &#62; </span> 
                    {user?.user_account}
                    <span className="greater-than"> &#62; </span> 
                    <span className="staffdetail-t3">View user</span>
                </h6>
                <h3 className="staffdetail-sub-heading mt-3 mb-0">Information</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0">User account</p>
                        <p className="mb-0">VCard</p>
                        <p className="mb-0">Status</p>
                        <p className="mb-0">Wallet</p>
                        <p className="mb-0">Name - Surname</p>
                        <p className="mb-0">Address</p>
                        <p className="mb-0">Email</p>
                        <p className="mb-0">Phone</p>
                        <p className="mb-0">Company</p>
                        {/* <p className="mb-0">V-Card</p>
                        <p className="mb-0">V-Card status</p> */}
                    </div>
                    <div className="div2">
                        <p className="mb-0">{user?.user_account}</p>
                        <p className="mb-0">{user?.vCard}</p>
                        {user?.status === 1 ? (
                            <p className="mb-0 color-42c78f">Enable</p>
                        ) : (
                            <p className="mb-0 text-danger">Disable</p>
                        )}
                        <p className="mb-0">{user?.wallet ? user?.wallet : 0}</p>
                        <p className="mb-0">{user?.name} {user?.surename}</p>
                        <p className="mb-0">{user?.address}</p>
                        <p className="mb-0">{user?.email}</p>
                        <p className="mb-0">{user?.phone_number}</p>
                        <p className="mb-0">{user?.company}</p>
                        {/* <p className="mb-0">122546969998</p> */}
                        {/* <p className="mb-0">
                            <span className="color-42c78f">Verify</span>
                            <button onClick={handleShow} className="btn-2 ms-4">Re-verify</button>
                        </p> */}
                    </div>
                </div>
                
                <h3 className="staffdetail-sub-heading mt-4 mb-0">User Details</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0 h-45">Number Of VCard ({staffList?.length ? staffList?.length : 0})</p>
                        {/* <p className="mb-0 h-45 pt-2">Money Transfer Statements</p> */}
                        {/* <p className="mb-0 h-45 pt-3">Extra edit fund</p> */}
                        <p className="mb-0 h-45 pt-3">Buy Points Statement</p>
                    </div>
                    <div className="div2">
                        <p className="mb-0 h-45">
                            <button className="btn-4 ms-4" onClick={() => navigate(`/user/staff-list/${user?.user_account}/${id}`)}>See VCard List</button>
                        </p>
                        {/* <p className="mb-0 h-45">
                            <button onClick={() => navigate(`/user/statement/${user?.name}/${id}`)} className="btn-4 ms-4">View</button>
                        </p> */}
                        {/* <p className="mb-0 h-45">
                            <button className="btn-4 ms-4" onClick={() => navigate(`/user/edit-fund/${user?.name}/${id}`)}>Edit fund</button>
                        </p> */}
                        <p className="mb-0 h-45">
                            <button className="btn-4 ms-4 " onClick={() => navigate(`/user/buypointsstatement/${user?.user_account}/${id}`)}>View</button>
                        </p>
                    </div>
                </div>
                
                <h3 className="staffdetail-sub-heading mt-4 mb-0">Bill Information</h3>
                <div className="d-flex mt-4 pt-2">
                    <div className="div1">
                        <p className="mb-0">Company</p>
                        <p className="mb-0 pt-2">Company Address</p>
                        <p className="mb-0 pt-3">Phone</p>
                        <p className="mb-0 pt-3">TAX ID</p>
                    </div>
                    <div className="div2">
                        <p className="mb-0">{data?.company_name}</p>
                        <p className="mb-0">
                            {data?.company_address}
                            {/* <button onClick={handleShow2} className="btn-2 ms-4">Allocate</button> */}
                        </p>
                        <p className="mb-0 pt-3">
                            {data?.company_phone}
                            {/* <button className="btn-2 ms-4" onClick={() => navigate(`/staff-report/1`)}>Report</button> */}
                        </p>
                        <p className="mb-0 pt-3">
                            {data?.company_tax_id}
                        </p>
                    </div>
                </div>
                <div className="mb-5 pt-4">
                    <button className="btn-3 mt-4" onClick={() => navigate(`/user/edit/${id}`)}>Edit</button>
                </div>

                {/* model one  */}
                
                <Modal show={show} onHide={handleClose} size="lg">
                    <div className="verify-email-div">
                        <h1>Verify V-card</h1>
                        <p className="pt-4 mt-4 mb-4 pb-4">Please confirm verification. V card in <b>angleria_2539@gmail.com</b> within 24 hours</p>

                        <div className="text-center">
                            <button className="btn-3 btn-1 me-2" onClick={handleClose}>Send</button>
                            <button className="btn-2 ms-2" onClick={handleClose}>Cancel</button>
                        </div>

                    </div>
                </Modal>

                {/* model two  */}
                
                <Modal show={show2} onHide={handleClose2} size="lg">
                    <div className="verify-email-div">
                        <h1>Edit allocate fund</h1>
                        <div className="p-3">
                            <h3>Fund</h3>
                            <div className="mt-3">
                                <input type="text" className="form-control d-inline-block" style={{width: 'calc(100% - 52px)'}} /> <label className="ms-2">THB</label>
                            </div>
                            <h3 className="mt-3">Automatic</h3>
                            <div className="mt-3">
                                <input className="p-2" type="radio" />
                                <label className="mx-2">Every</label>
                                <select className="form-control d-inline-block w-25">
                                    <option>Select Day</option>
                                </select>
                                <label className="mx-2">and time</label>
                                <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                                <label className="ms-2">in week.</label>
                            </div>
                            
                            <div className="d-flex justify-content-between mt-3">
                                <div className="w-25 bdr-t"></div>
                                <div className="w-15" style={{width: '15%'}}><lable>Or</lable></div>
                                <div className="w-25 bdr-t"></div>
                            </div>
                            
                            <div className="mt-3">
                                <input className="p-2" type="radio" />
                                <label className="mx-2">Every</label>
                                <select className="form-control d-inline-block w-25">
                                    <option>Select Day</option>
                                </select>
                                <label className="mx-2">and time</label>
                                <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                                <label className="ms-2">in month.</label>
                            </div>

                            <h3 className="mt-3">Manual</h3>
                            
                            <div className="mt-3">
                                <input className="p-2" type="radio" />
                                <div className="d-inline-block ms-2">
                                    <ReactDatePicker />
                                </div>                            
                                <label className="mx-2">and time</label>
                                <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                                <label className="ms-2">in month.</label>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <button className="btn-3 btn-1 me-2" onClick={handleClose2}>Send</button>
                            <button className="btn-2 ms-2" onClick={handleClose2}>Cancel</button>
                        </div>

                    </div>
                </Modal>

            </div>
        )
    }
}

export default ViewUser;