import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SemiPieChart from "../../common/SemiPieChart";
import ColumnChart from "../../common/ColumnChart";
import DataTableBase from "../../common/DateTableBase";
import axios from "axios";
import { API_URL, currentMonthYear } from "../../../utility";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { FaBahtSign } from "react-icons/fa6";



const Overview = () => {
    const [data, setData] = useState([]);
    const [flag, setFlag] = useState(2);

    const [loading, setLoading] = useState(true);

    let count = 0;

    const navigate = useNavigate();
    
    
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/overview`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Admin OVerview res : ", res);
            setData(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Admin Overview error : ", err);
        })
    }, [flag])

    const columns = [
        {
            name: 'No.',
            width: '70px',
            selector: (row, index) => index+1,
        },
        {
            name: 'User',
            selector: row => row?.name,
        },
        // {
        //     name: 'Status',
        //     selector: row => row?.status === "Success"? <span className="active-status">Success</span> : row?.status === "Pending" ? <span className="text-warning">Pending</span> : <span className="inactive-status">Failed</span>,
        // },
        {
            name: 'Total Charging',
            width: '150px',
            selector: row => Number(row?.fund_amount).toLocaleString(),
        }
    ];

    const columns2 = [
        {
            name: '#',
            width: '120px',
            selector: row => row?.request_id,
        },
        {
            name: 'Date',
            width: '110px',
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[0];
                    return formateDate;                
                }
            },
        },
        {
            name: 'Time',
            width: '100px',            
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[1];
                    let time = formateDate?.split('.')[0];
                    return time;
                }
            },
        },
        {
            name: 'User',
            selector: row => row?.name,
        },
        {
            name: 'Transfer',
            width: '150px',
            selector: row => row?.transfer_type,
        },
        // {
            //     name: 'Status',
            //     selector: row => row.status === 1? <span className="active-status">Active</span> : <span className="inactive-status">Inactive</span>,
            // },
        {
            name: 'Fund',
            width: '150px',
            selector: row => row?.fund_amount,
        },
        {
            name: 'View',
            width: '90px',
            selector: row => <svg className="pointer" onClick={() => navigate(`/fund-request/detail/${row?.id}`)} xmlns="http://www.w3.org/2000/svg" width="22.771" height="22.771" viewBox="1746.229 679.584 22.771 22.771"><path d="M1756.102 679.612c-5.445 0-9.873 4.429-9.873 9.873 0 5.444 4.428 9.873 9.873 9.873 1.664 0 3.3-.395 4.682-1.157.111.134.234.256.367.367l2.82 2.821a2.877 2.877 0 1 0 4.063-4.062l-2.82-2.82a2.821 2.821 0 0 0-.452-.368c.761-1.382 1.24-2.99 1.24-4.682 0-5.445-4.428-9.873-9.872-9.873Zm0 2.82a7.019 7.019 0 0 1 7.052 7.053c0 1.862-.677 3.582-1.862 4.852l-.085.084c-.133.111-.256.234-.366.367-1.241 1.129-2.934 1.777-4.768 1.777a7.019 7.019 0 0 1-7.052-7.052 7.019 7.019 0 0 1 7.052-7.052Z" fill="#7c7f84" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>,
        },
    ];

    const data1 = [
        {
            id: 1,
            status: 1,
            staff: 'Staff A',
            total: '50,000'
        },
        {
            id: 2,
            status: 1,
            staff: 'Staff B',
            total: '35,000'
        },
        {
            id: 3,
            status: 0,
            staff: 'Staff C',
            total: '55,000'
        },
        // {
        //     id: 5,
        //     status: 1,
        //     staff: 'Staff D',
        //     total: '55,000'
        // },
        // {
        //     id: 6,
        //     status: 1,
        //     staff: 'Staff E',
        //     total: '55,000'
        // },
        // {
        //     id: 7,
        //     status: 0,
        //     staff: 'Staff G',
        //     total: '55,000'
        // },
        // {
        //     id: 8,
        //     status: 1,
        //     staff: 'Staff H',
        //     total: '85,000'
        // },
    ]

    const data2 = [
        {
            numb: '#1wq87sc6',
            date: '16-09-2023',
            time: '12:00',
            staff: 'Staff A',
            user: 'Kerry',
            transfer: 'Bank transfer',
            status: 1,
            fund: 500,
        },
        {
            numb: '#2ssa8adf2s',
            date: '16-09-2023',
            time: '12:00',
            user: 'Flash',
            staff: 'Staff B',
            transfer: 'Card',
            status: 0,
            fund: 500,
        },
        {
            numb: '#1wq87sc6',
            user: 'Kerry',
            date: '16-09-2023',
            time: '12:00',
            user: 'Kerry',
            transfer: 'Bank transfer',
            staff: 'Staff C',
            status: 1,
            fund: 500,
        },
        {
            numb: '#1wq87sc6',
            date: '16-09-2023',
            time: '12:00',
            user: 'Flash',
            transfer: 'QR Code',
            staff: 'Staff D',
            status: 0,
            fund: 500,
        },
        {
            numb: '#1wq87sc6',
            date: '16-09-2023',
            user: 'Kerry',
            time: '12:00',
            staff: 'Staff E',
            status: 1,
            transfer: 'Bank transfer',
            fund: 500,
        },
        
    ]
    if(loading){
        return (
            <Loader />
        )
    } else {
        return (
            <div className="overview">
                <h1 className="overview-t1">Overview</h1>
                <Row className="mt-5">
                    <Col md={6}>
                        <div className="overview-card">
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="413 215 30 26.25"><path d="M440.023 220.625h-22.336a.937.937 0 1 1 0-1.875h22.5c.518 0 .938-.42.938-.938a2.812 2.812 0 0 0-2.813-2.812H416.75a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.262 2.977-2.813v-15c0-1.55-1.335-2.812-2.977-2.812Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.751 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg> */}
                                        {/* <FaBahtSign color="#fff" size="0.6cm" /> */}
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="29.063" height="29.063"><path d="M14.531 0C6.504 0 0 6.504 0 14.531c0 8.028 6.504 14.531 14.531 14.531 8.028 0 14.531-6.503 14.531-14.53C29.063 6.503 22.56 0 14.533 0Zm0 5.625a5.156 5.156 0 1 1 0 10.313 5.156 5.156 0 0 1 0-10.313Zm0 20.156c-3.44 0-6.521-1.558-8.584-3.996 1.102-2.074 3.258-3.504 5.772-3.504.14 0 .281.024.416.065.761.246 1.558.404 2.396.404.838 0 1.64-.158 2.397-.404.134-.041.275-.065.416-.065 2.513 0 4.67 1.43 5.771 3.504-2.062 2.438-5.144 3.996-8.584 3.996Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-user-circle"/></svg>
                                        Biglot */}
                                        User List
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalUser ? (Number(data?.totalUser)).toLocaleString() : 0} </h2>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="29.063" height="29.063"><path d="M14.531 0C6.504 0 0 6.504 0 14.531c0 8.028 6.504 14.531 14.531 14.531 8.028 0 14.531-6.503 14.531-14.53C29.063 6.503 22.56 0 14.533 0Zm0 5.625a5.156 5.156 0 1 1 0 10.313 5.156 5.156 0 0 1 0-10.313Zm0 20.156c-3.44 0-6.521-1.558-8.584-3.996 1.102-2.074 3.258-3.504 5.772-3.504.14 0 .281.024.416.065.761.246 1.558.404 2.396.404.838 0 1.64-.158 2.397-.404.134-.041.275-.065.416-.065 2.513 0 4.67 1.43 5.771 3.504-2.062 2.438-5.144 3.996-8.584 3.996Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-user-circle"/></svg>
                                        Biglot Active */}
                                        User Active
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalActiveUser ? (Number(data?.totalActiveUser)).toLocaleString() : 0} </h2>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25"><path d="M15 0C6.716 0 0 5.456 0 12.188c0 2.903 1.254 5.566 3.341 7.66-.736 2.948-3.183 5.58-3.212 5.611a.468.468 0 0 0 .34.791c3.882 0 6.796-1.86 8.238-3.01A17.851 17.851 0 0 0 15 24.374c8.284 0 15-5.456 15-12.188C30 5.457 23.284 0 15 0Zm1.406 17.721v1.029c0 .518-.42.938-.937.938h-.938a.937.937 0 0 1-.937-.938v-1.039a4.28 4.28 0 0 1-1.862-.671c-.365-.241-.4-.769-.091-1.077l1.026-1.027c.22-.219.546-.248.827-.119.186.086.39.13.601.13h1.921a.494.494 0 0 0 .136-.968l-2.934-.838c-1.304-.372-2.344-1.448-2.514-2.793a3.297 3.297 0 0 1 2.89-3.695V5.625c0-.518.42-.938.937-.938h.938c.518 0 .937.42.937.938v1.039a4.28 4.28 0 0 1 1.862.671c.365.241.4.769.091 1.077L17.333 9.44c-.22.219-.546.248-.827.119a1.436 1.436 0 0 0-.601-.13h-1.921a.494.494 0 0 0-.136.968l2.934.838c1.304.373 2.344 1.448 2.514 2.793.237 1.879-1.115 3.482-2.89 3.694Z" fill="#fff"  fill-rule="evenodd" data-name="Icon awesome-comment-dollar"/></svg>
                                        Fund */}
                                        VCard
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalFund ? (Number(data?.totalFund)).toLocaleString() : 0} </h2>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                        {/* <FaBahtSign color="#fff" size="0.6cm" />
                                        Money */}
                                        Top  Up Points
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalMoney ? (Number(data?.totalMoney)).toLocaleString() : 0} </h2>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="1743 390.168 30 26.25"><path d="M1770.023 395.793h-22.335a.937.937 0 1 1 0-1.875h22.5c.517 0 .937-.42.937-.937a2.812 2.812 0 0 0-2.813-2.813h-21.562a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.261 2.977-2.812v-15c0-1.551-1.335-2.813-2.977-2.813Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg>
                                        Points Alocated */}
                                        Points Used
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalPoints ? (Number(data?.totalPoints)).toLocaleString() : 0} </h2>
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                <div className="w-70">
                                    <h2 className="overview-t2">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="1743 390.168 30 26.25"><path d="M1770.023 395.793h-22.335a.937.937 0 1 1 0-1.875h22.5c.517 0 .937-.42.937-.937a2.812 2.812 0 0 0-2.813-2.813h-21.562a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.261 2.977-2.812v-15c0-1.551-1.335-2.813-2.977-2.813Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg>
                                        Points Used */}
                                        Points Balance
                                    </h2>
                                </div>
                                <div className="w-30">
                                    <h2 className="overview-t3">{data?.totalUsedPoint ? (Number(data?.totalUsedPoint)).toLocaleString() : 0} </h2>
                                </div>
                            </div>

                            {/* <h2 className="overview-t4 mt-38">TOTAL INCOME</h2>
                            <h2 className="overview-t4 mt-3">0 </h2> */}
                        </div>
                    </Col>
                    {/* <Col md={6}>
                        <SemiPieChart />
                    </Col>
                    <Col md={6}>
                        <div className="overview-card d-flex">
                            <div className="w-50">
                                <h6 className="overview-t2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="26.25" viewBox="1146.125 205.168 33.75 26.25"><path d="M1170.5 214.543c0-5.178-5.456-9.375-12.188-9.375-6.73 0-12.187 4.197-12.187 9.375 0 2.008.828 3.864 2.225 5.39-.783 1.771-2.077 3.174-2.096 3.194a.468.468 0 0 0 .34.791c2.143 0 3.921-.718 5.199-1.464 1.886.923 4.118 1.464 6.52 1.464 6.73 0 12.187-4.197 12.187-9.375Zm-13.125 5.625v-.954a3.355 3.355 0 0 1-1.838-.665.47.47 0 0 1-.033-.712l.688-.657a.48.48 0 0 1 .594-.042c.226.141.484.218.75.218h1.648c.38 0 .691-.347.691-.773 0-.349-.211-.656-.514-.746l-2.636-.791c-1.09-.327-1.85-1.372-1.85-2.542 0-1.437 1.115-2.604 2.5-2.641v-.945c0-.259.21-.469.468-.469h.938c.259 0 .468.21.468.47v.954a3.35 3.35 0 0 1 1.838.665.47.47 0 0 1 .034.711l-.689.657a.48.48 0 0 1-.593.043 1.406 1.406 0 0 0-.751-.218h-1.647c-.381 0-.692.347-.692.773 0 .348.212.655.514.745l2.637.791c1.09.327 1.85 1.373 1.85 2.543 0 1.437-1.116 2.604-2.5 2.64v.945c0 .26-.21.469-.469.469h-.937a.469.469 0 0 1-.469-.469Zm20.274 7.265c1.398-1.525 2.226-3.382 2.226-5.39 0-3.922-3.134-7.277-7.578-8.676.05.387.078.779.078 1.176 0 6.203-6.308 11.25-14.063 11.25-.631 0-1.249-.045-1.859-.11 1.848 3.37 6.182 5.735 11.235 5.735 2.4 0 4.633-.541 6.52-1.464 1.277.746 3.055 1.464 5.198 1.464a.468.468 0 0 0 .34-.791c-.019-.02-1.314-1.42-2.097-3.194Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-comments-dollar"/></svg>
                                    <span>Spend</span>
                                </h6>
                                <h2 className="overview-t3">12,000,000 THB</h2>
                                <div>
                                    <p className="overview-t4 mb-0 pt-2 mt-4">TOTAL FUND</p>
                                    <p className="overview-t4">158,000,587 THB</p>
                                </div>
                            </div>
                            <div className="w-50 card-left-div">
                                <h2 className="overview-t5">3,800,587 THB</h2>
                                <h6 className="overview-t6">
                                    <span>Remain</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.25" viewBox="1743 390.168 30 26.25"><path d="M1770.023 395.793h-22.335a.937.937 0 1 1 0-1.875h22.5c.517 0 .937-.42.937-.937a2.812 2.812 0 0 0-2.813-2.813h-21.562a3.75 3.75 0 0 0-3.75 3.75v18.75a3.75 3.75 0 0 0 3.75 3.75h23.273c1.642 0 2.977-1.261 2.977-2.812v-15c0-1.551-1.335-2.813-2.977-2.813Zm-2.648 12.188a1.875 1.875 0 1 1 0-3.75 1.875 1.875 0 0 1 0 3.75Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-wallet"/></svg>
                                </h6>
                            </div>
                        </div>
                    </Col> */}

                    {/* <Col md={12} className="mb-4 pb-4">
                        <h2 className="overview-t6">Spend Chart</h2>
                        <ColumnChart />
                    </Col> */}
                    <Col md={6}>
                        <div className="overview-table-div">
                            <h2 className="overview-t7">
                                <svg xmlns="http://www.w3.org/2000/svg" width="33.75" height="30" viewBox="418 965.832 33.75 30"><path d="M450.344 969.582h-6.094v-2.344c0-.779-.627-1.406-1.406-1.406h-15.938c-.779 0-1.406.627-1.406 1.406v2.344h-6.094c-.779 0-1.406.627-1.406 1.406v3.282c0 2.091 1.318 4.242 3.627 5.9 1.846 1.33 4.09 2.174 6.445 2.443 1.84 3.053 3.99 4.313 3.99 4.313v4.219h-2.812c-2.068 0-3.75 1.212-3.75 3.28v.704c0 .387.316.703.703.703h17.344a.705.705 0 0 0 .703-.703v-.703c0-2.069-1.682-3.281-3.75-3.281h-2.813v-4.22s2.15-1.259 3.99-4.312c2.362-.27 4.606-1.113 6.446-2.443 2.303-1.658 3.627-3.809 3.627-5.9v-3.282c0-.779-.627-1.406-1.406-1.406Zm-26.526 7.547c-1.43-1.031-2.068-2.18-2.068-2.86v-.937h3.762c.058 1.91.34 3.586.75 5.05a9.443 9.443 0 0 1-2.444-1.253Zm24.182-2.86c0 .944-1.037 2.116-2.068 2.86a9.48 9.48 0 0 1-2.45 1.254c.41-1.465.692-3.14.75-5.051H448v.938Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-trophy"/></svg>
                                TOP 3 Add Fund (Grand Total)
                                {/* <span>{currentMonthYear()}</span> */}
                            </h2>
                            <DataTableBase 
                                columns={columns} 
                                data={data?.top3Funds ? data?.top3Funds : []} 
                                // data={[]} 
                            />
                        </div>
                    </Col>
                    <Col className="mt-5">
                        <div className="overview-table-div2">
                            <h2 className="overview-t8 pb-2">Last 10 Add Fund <span className="pointer" onClick={() => {localStorage.setItem("ba_tab", "3"); navigate('/fund-requests');}}>View all</span></h2>
                            <DataTableBase 
                                columns={columns2} 
                                data={data?.last10Funds ? data?.last10Funds : []} 
                                // data={[]} 
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Overview;