import React from "react";
import logo from "../../../asserts/logo.png";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
    const navigate = useNavigate();

    return (
        <div className="login-page">
            <div className="content-div">
                <div className="text-center">
                    <img src={logo} className="logo" alt="logo" />
                </div>
                <h1 className="forget-t1 mt-3 mb-0">Recover your password</h1>
                <p className="mt-2 forget-t2 mb-0"><b>Enter the email</b> that you used when register to recover your password. You will receive a <b>password reset link.</b></p>
                <div className="mt-4">                    
                    <div className="margin-auto w-100-50">
                        <input type="text" className="form-control w-100" placeholder="User or Email" />
                    </div>
                </div> 
                <div className="d-flex mt-4">
                    <div className="w-100-50 margin-auto">
                        <button className="login-btn" onClick={() => navigate('/reset-password')}>SEND LINK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;