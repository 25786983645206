import React from "react";
import Sidebar from "../common/Sidebar";
// import Overview from "./pages/Overview";
import { Outlet } from "react-router-dom";

const User = () => {
    return (
        <>
            <div className="user d-flex">
                <Sidebar />
                <div className="main-content">
                    {/* <Overview /> */}
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default User;