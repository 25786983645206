import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const ReactDatePicker = ({date, setDate}) => {
  // const [startDate, setStartDate] = useState();

  return (
    <div className="react-datepicker-div">
        <DatePicker 
            selected={date} 
            onChange={(datee) => setDate(datee)} 
            placeholderText="DD-MM-YYYY"
            className="react-datepickerr"
            // style={{width: '180px'}}
        />
        <svg xmlns="http://www.w3.org/2000/svg" width="18.375" height="24" viewBox="15.313 8 18.375 24"><path d="M33.195 15.5h-17.39c-.272 0-.492-.252-.492-.562V13.25c0-1.243.88-2.25 1.968-2.25h1.969V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h5.25V8.562c0-.31.22-.562.492-.562h1.64c.273 0 .493.252.493.563V11h1.969c1.087 0 1.968 1.007 1.968 2.25v1.688c0 .31-.22.562-.492.562ZM15.805 17h17.39c.272 0 .492.252.492.563V29.75c0 1.243-.88 2.25-1.968 2.25H17.28c-1.087 0-1.968-1.007-1.968-2.25V17.562c0-.31.22-.562.492-.562Zm13.67 4.498-1.155-1.332a.45.45 0 0 0-.696-.003l-4.35 4.93-1.885-2.172a.45.45 0 0 0-.697-.003l-1.164 1.32a.62.62 0 0 0-.003.796l3.388 3.903a.45.45 0 0 0 .696.003l5.863-6.647a.62.62 0 0 0 .003-.795Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-calendar-check"/></svg>
    </div>
  );
};

export default ReactDatePicker;