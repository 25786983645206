import Spinner from 'react-bootstrap/Spinner';

function Loader() {
  return (
    <div className='loaderr'>
        <Spinner animation="border" />
    </div>
  );
}

export default Loader;