import React, { useEffect, useState } from "react";
import DataTableBase from "../../common/DateTableBase";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../utility";
import axios from "axios";
import Loader from "../../common/Loader";
import SweetAlert from "react-bootstrap-sweetalert";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as XLSX from 'xlsx';
import { Spinner } from "react-bootstrap";


const UserList = () => {
    const navigate = useNavigate();

    const [excelData, setExcelData] = useState(null);
    const [id, setId] = useState(null);
    const [userList, setUserList] = useState([]);
    const [flag, setFlag] = useState(2);
    
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);

    const [delId, setDelId] = useState(null);

    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);

    const [companyName, setCompanyName] = useState(null);
    
    const handleClose = () => setShow2(false);
    const handleShow = () => setShow2(true);
    
    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("User list res : ", res);
            setUserList(res?.data?.users);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("staff list error : ", err);
        })
    }, [flag])


    function isDuplicate(array) {
        const seenVolta = new Set();
        const seenVin = new Set();
        const seenRFID = new Set();
        const seenREGNO = new Set();
      
        for (const item of array) {
          if (seenVolta.has(item?.VOLTA) || seenVin.has(item?.VIN) || seenRFID.has(item['RFID No.']) || seenREGNO.has(item['Reg No.'])) {
            return true; // Duplicate found
          }
          seenVolta.add(item?.VOLTA);
          seenVin.add(item?.VIN);
          seenRFID.add(item['RFID No.']);
          seenREGNO.add(item['Reg No.']);
        }
      
        return false; // No duplicates found
    }


    const deleteFunc = (idd) => {
        if(idd){
            setLoading(true);
            setShow(false);
            console.log("idd : ", idd);
            const token = localStorage.getItem("admin_token");
            axios.get(`${API_URL}/admin/users/${idd}/delete`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
                console.log("Delete user res : ", res);
                // localStorage.setItem("admin_token", res?.data?.token);
                setFlag(flag + 1);
                setLoading(false);
                setDelId(null);
            }).catch((err) => {
                // setShow(false);                
                console.log("Delete User Error : ", err);
                setLoading(false);
                // setError(err?.response?.data?.message);
            })
        }        
    }


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        console.log("file : ", file);
        const fileName = file?.name?.split('.xlsx')[0]
        console.log("file Name : ", fileName);
        if(fileName === companyName){
            reader.onload = (event) => {
              const workbook = XLSX.read(event.target.result, { type: 'binary' });
              const sheetName = workbook.SheetNames[0];
              const sheet = workbook.Sheets[sheetName];
              const data = XLSX.utils.sheet_to_json(sheet);
              console.log("data excel : ", data);
              if(data?.length > 0){
                console.log("result : ", isDuplicate(data));
                if(isDuplicate(data)){
                    toast.error("Row duplication error.");
                } else {
                    setExcelData(data);
                }
              } else {
                toast.error("Empty file");
              }
            };
        
            reader.readAsBinaryString(file);
        } else {
            toast.error("File Name does not match with company name in row.")
            // handleClose()
        }
        // console.log("reader.readAsBinaryString(file) : ", reader.readAsBinaryString(file))
    };


    const columns = [
        {
            name: 'No.',
            selector: row => row?.id,
            maxWidth: '50px'
        },
        // {
        //     name: 'Staff Account',
        //     selector: row => row?.staff,
        // },
        {
            name: 'Company',
            selector: row => `${row?.company}`,
        },
        {
            name: 'Status',
            maxWidth: '140px',
            selector: row => <span className={row?.status === 1 ? "text-success" :  "text-danger"}>{row?.status === 1 ? "Enable" : "Disable"}</span>
        },
        {
            name: 'Money',
            selector: row => row?.money? Number((Number(row?.money)).toFixed(2)).toLocaleString() : 0,
            maxWidth: '150px'
        },
        {
            name: 'Points',
            selector: row => row?.points ? Number((Number(row?.points)).toFixed(0)).toLocaleString() : 0,
            maxWidth: '150px'
        },
        {
            name: 'Tools',
            maxWidth: '150px',
            selector: row => <>
                <button title="User detail" className="title-btns">
                    <svg title="User Detail" onClick={() => navigate(`/view-user/${row?.id}`)} className="pointer me-1" xmlns="http://www.w3.org/2000/svg" width="32.083" height="25.667" viewBox="1600.797 306.958 32.083 25.667"><path d="M1616.036 314.979a4.012 4.012 0 0 0-4.01 4.01 4.012 4.012 0 0 0 4.01 4.01 4.012 4.012 0 0 0 4.01-4.01 4.012 4.012 0 0 0-4.01-4.01Zm13.636-8.021h-25.667a3.218 3.218 0 0 0-3.208 3.208v19.25a3.218 3.218 0 0 0 3.208 3.208h25.667a3.218 3.218 0 0 0 3.208-3.208v-19.25a3.218 3.218 0 0 0-3.208-3.208Zm-5.15 22.795-4.668-4.668a7.113 7.113 0 0 1-3.834 1.123c-3.978 0-7.202-3.225-7.202-7.219a7.21 7.21 0 0 1 7.218-7.219 7.21 7.21 0 0 1 7.22 7.219 7.154 7.154 0 0 1-1.124 3.834l4.668 4.652-2.277 2.278Z" fill="#303778" fill-rule="evenodd" data-name="Icon material-pageview"/></svg>
                </button>
                <button title="Edit User" className="title-btns">
                    <svg onClick={() => navigate(`/user/edit/${row?.id}`)} className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="33.75" height="29.994" viewBox="1652.88 302.958 33.75 29.994"><path d="m1676.47 307.827 5.285 5.285a.573.573 0 0 1 0 .809l-12.797 12.797-5.437.603a1.14 1.14 0 0 1-1.26-1.26l.603-5.437 12.797-12.797a.573.573 0 0 1 .809 0Zm9.492-1.342-2.86-2.86a2.292 2.292 0 0 0-3.234 0l-2.074 2.075a.573.573 0 0 0 0 .809l5.285 5.285a.573.573 0 0 0 .809 0l2.074-2.075c.89-.896.89-2.343 0-3.234Zm-10.582 16.752v5.965h-18.75v-18.75h13.465a.72.72 0 0 0 .498-.205l2.344-2.344a.703.703 0 0 0-.499-1.201h-16.746a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h20.625a2.813 2.813 0 0 0 2.813-2.813v-9.246c0-.627-.756-.937-1.201-.498l-2.344 2.344a.72.72 0 0 0-.205.498Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-edit"/></svg>
                </button>
                <button className="title-btns" title="Upload File">
                    <svg onClick={() => {setCompanyName(row?.company); setId(row?.id); handleShow();}} className="pointer mx-1" xmlns="http://www.w3.org/2000/svg" width="22.5" height="30" viewBox="1706.63 302.958 22.5 30"><path d="M1726.317 306.708h-4.687a3.753 3.753 0 0 0-3.75-3.75 3.753 3.753 0 0 0-3.75 3.75h-4.688a2.813 2.813 0 0 0-2.812 2.812v20.625a2.813 2.813 0 0 0 2.812 2.813h16.875a2.813 2.813 0 0 0 2.813-2.813V309.52a2.813 2.813 0 0 0-2.813-2.812Zm-14.062 21.094c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm0-5.625c-.78 0-1.406-.627-1.406-1.407 0-.779.627-1.406 1.406-1.406.78 0 1.406.627 1.406 1.406 0 .78-.627 1.407-1.406 1.407Zm5.625-11.25c.78 0 1.406.626 1.406 1.406 0 .78-.627 1.406-1.406 1.406-.78 0-1.406-.627-1.406-1.406 0-.78.627-1.406 1.406-1.406Zm7.5 21.562a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Zm0-5.625a.47.47 0 0 1-.469.469h-8.437a.47.47 0 0 1-.47-.469v-.937a.47.47 0 0 1 .47-.47h8.437a.47.47 0 0 1 .469.47v.937Z" fill="#303778" fill-rule="evenodd" data-name="Icon awesome-clipboard-list"/></svg>
                </button>
                {/* <button className="title-btns" title="Delete User">
                    <svg onClick={() => {setDelId(row?.id); setShow(true); }} className="pointer ms-1" xmlns="http://www.w3.org/2000/svg" width="24.37" height="29.994" viewBox="1749.13 302.957 24.37 29.994"><path d="M1751.004 312.33v18.747a1.88 1.88 0 0 0 1.875 1.874h16.872a1.88 1.88 0 0 0 1.874-1.874V312.33h-20.62Zm5.624 16.872h-1.874v-13.123h1.874v13.123Zm3.75 0h-1.875v-13.123h1.875v13.123Zm3.749 0h-1.875v-13.123h1.875v13.123Zm3.75 0h-1.875v-13.123h1.874v13.123Zm4.217-22.496h-6.092v-2.343a1.41 1.41 0 0 0-1.406-1.406h-6.562a1.41 1.41 0 0 0-1.406 1.406v2.343h-6.092a1.41 1.41 0 0 0-1.406 1.406v2.344h24.37v-2.344a1.41 1.41 0 0 0-1.406-1.406Zm-7.967 0h-5.624v-1.85h5.624v1.85Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon metro-bin"/></svg>
                </button> */}
            </>
        }
    ];

    const onSubmit = () => {
        if(excelData){
            if(excelData[0]?.hasOwnProperty("Customer Name")) {
                // let check1 = 1;
                // for(let i = 0; i < excelData?.length; i++){
                //     if(excelData["Customer Name"] !== companyName){
                //         check1 = 2;
                //         break;
                //     }
                // }
                // if(check1 === 1){
                    // console.log("The object has the 'Customer Name' key.");
                    setLoading2(true);
                    console.log("Excel data : ", excelData);
                    const token = localStorage.getItem("admin_token");
                    // console.log("token : ", token);
                    axios.post(`${API_URL}/admin/users/${id}/store-data`, excelData, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
                        console.log("Store data res : ", res);
                        handleClose();
                        setId(null);
                        setLoading2(false);
                        toast.success("Data uploaded successfully.");
                        setExcelData(null);
                        setCompanyName(null);
                    }).catch((err) => {
                        toast.error(err?.response?.data?.error);
                        setLoading2(false);
                        handleClose();
                        setCompanyName(null);
                        setId(null);
                        console.log("Store data error : ", err);
                    })
                // } else {
                //     toast.error("'Customer Name' in file does not match with row.")
                //     setExcelData(null);
                // }
            } else {
                toast.error("The excel file does not have the 'Customer Name' key.")
                setExcelData(null);
            }
        }
    }

    return (
        <div className="stafflist-page">
            <ToastContainer autoClose={2500} />
            <Modal show={show2} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                <Modal.Title>Select an excel file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading2 ? (
                        <div style={{paddingLeft: 'calc(50% - 25px)'}}>
                            <Spinner animation="border"  />
                        </div>
                    ) : (
                        <div className="w-100">
                            <input type="file" onChange={handleFileUpload} className="form-control" placeholder="select file" />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                <a href="https://pea3-chargingnetwork.com/biglot-api/Samples/Sample%20Company.xlsx" target="_blank" download>
                    <Button  variant="success">
                        Download Sample 
                    </Button>
                </a>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button  variant="primary" onClick={onSubmit}>
                    Send File
                </Button>
                </Modal.Footer>
            </Modal>
            {show && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, delete it!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    title="Are you sure?"
                    onConfirm={() => {deleteFunc(delId)}}
                    onCancel={() => {setDelId(null); setShow(false)}}
                    focusCancelBtn
                >
                    You will not be able to recover this!
                </SweetAlert>
            )}

            <h1 className="stafflist-t1">
                User List 
                <button onClick={() => navigate('/user/add')} className="btn-1 ms-5" style={{position: 'relative', top: '-6px'}}>
                    Add user 
                    <svg className="ms-3" xmlns="http://www.w3.org/2000/svg" width="20.587" height="20.587" viewBox="838.955 86 20.587 20.587"><path d="M858.071 94.088h-6.617V87.47a1.47 1.47 0 0 0-1.47-1.47h-1.47a1.47 1.47 0 0 0-1.471 1.47v6.618h-6.617a1.47 1.47 0 0 0-1.47 1.47v1.47c0 .813.658 1.471 1.47 1.471h6.617v6.617c0 .812.658 1.47 1.47 1.47h1.47a1.47 1.47 0 0 0 1.471-1.47V98.5h6.617a1.47 1.47 0 0 0 1.47-1.47v-1.47a1.47 1.47 0 0 0-1.47-1.471Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-plus"/></svg>
                </button>
            </h1>
            {/* <div className="d-flex justify-content-between mt-5">
                <div className="pt-4">
                    <h3 className="stafflist-t2 mb-0">Staff detail (2)</h3>
                </div>
                <div>
                    <div className="p-relative">
                        <input type="text" style={{width: '300px'}} className="form-control" />
                        <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="23.062" height="23.062" viewBox="1764.938 75.468 23.062 23.062"><path d="M1774.937 75.497c-5.514 0-10 4.485-10 10 0 5.513 4.486 9.998 10 9.998 1.685 0 3.342-.4 4.742-1.171.112.135.237.26.372.371l2.856 2.857a2.914 2.914 0 1 0 4.114-4.114l-2.857-2.857a2.857 2.857 0 0 0-.457-.371c.772-1.4 1.257-3.028 1.257-4.742 0-5.514-4.485-10-9.999-10Zm0 2.857a7.109 7.109 0 0 1 7.142 7.142c0 1.886-.686 3.628-1.886 4.914l-.085.086c-.135.112-.26.236-.372.371-1.257 1.143-2.97 1.8-4.828 1.8a7.109 7.109 0 0 1-7.142-7.142 7.109 7.109 0 0 1 7.142-7.143Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                    </div>
                </div>
            </div> */}
            <div className="staff-table-div mt-4 pt-3">
                {loading ? (
                    <Loader />
                ) : (
                    <DataTableBase 
                        columns={columns}
                        data={userList}
                        pagination
                    />
                )}
            </div>
        </div>
    )
}

export default UserList;