// export const API_URL = 'https://biglot-backend.pea-cpo.com/api/v1';
export const API_URL = 'https://pea3-chargingnetwork.com/biglot-api/api/v1';

export function generateRandomPassword(length) {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset.charAt(randomIndex);
    }
    
    return password;
}


export function currentMonthYear(){
  const currentDate = new Date();
  const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  const formattedDate = `${currentMonth} ${currentYear}`;

  // console.log(formattedDate); // Output: "SEP 2023" (depending on the current month and year)
  return formattedDate;
}


//   // Example: Generate a random password of length 9
//   const randomPassword = generateRandomPassword(9);
//   console.log(randomPassword);
  