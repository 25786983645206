import React, { useEffect, useState } from "react";
import DataTableBase from "../../common/DateTableBase";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utility";
import Loader from "../../common/Loader";

const dataa = [
    {
        id:1,
        staff: 'Saff A',
        name: 'Angleria Horgan',
        status: 'Active',
        wallet: 2000
    },
    {
        id:2,
        staff: 'Saff B',
        name: 'Ethan Hunt',
        status: 'Inactive',
        wallet: 5000
    },
    {
        id:3,
        staff: 'Saff C',
        name: 'The Rock',
        status: 'Pending',
        wallet: 3000
    },
    {
        id:4,
        staff: 'Saff D',
        name: 'Wyn Johnson',
        status: 'Incomplete',
        wallet: 5000
    },
]

const ListStaff = () => {
    const [flag, setFlag] = useState(2);
    const [staffList, setStaffList] = useState([]);
    const [filteredStaffList, setFilteredStaffList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(null);

    const navigate = useNavigate();
    const { id, name } = useParams();

    const handleSearch = (e) => {
        const value = e.target.value;

        const filteredArray = staffList?.filter(item => item?.staff_vcard?.toLowerCase()?.includes(value.toLowerCase()) || item?.vin_id?.toLowerCase()?.includes(value?.toLowerCase()) || item?.volta?.toLowerCase()?.includes(value?.toLowerCase()) || item?.registration_no?.toLowerCase()?.includes(value?.toLowerCase()) || item?.rfid_no?.toLowerCase()?.includes(value?.toLowerCase()))
        // console.log(filteredArray);
        setFilteredStaffList(filteredArray);
    }

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users/${id}/staffs`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Staff list res : ", res);
            setStaffList(res?.data?.staffList);
            setFilteredStaffList(res?.data?.staffList);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("staff list error : ", err);
        })
    }, [flag])

    const columns = [
        {
            name: 'No.',
            width: '60px',
            selector: (row, index) => index + 1,
        },
        // {
            //     name: 'Staff Account',
            //     selector: row => row?.staff,
        // },
        // {
        //     name: 'Staff Account',
        //     selector: row => row?.staff,
        // },
        // {
            //     name: 'Name - Surname',
            //     selector: row => row?.name,
            // },
        {
            name: 'VIN',
            minWidth: '190px',
            selector: row => row?.vin_id,
        },
        {
            name: 'VCard',
            minWidth: '120px',
            selector: row => row?.staff_vcard,
        },
        {
            name: 'RFID No.',
            selector: row => row?.rfid_no,
        },
        {
            name: 'Volta',
            minWidth: '180px',
            selector: row => row?.volta,
        },
        {
            name: 'Model',
            selector: row => row?.model,
        },
        {
            name: 'Color Desc',
            minWidth: '160px',
            selector: row => row?.color_desc,
        },
        {
            name: 'Reg No.',
            minWidth: '160px',
            selector: row => row?.registration_no,
        },
        {
            name: 'Status',
            selector: row => <span className={row?.staff_status === "1" ? "text-success" : row?.staff_status === '0'? "text-danger" : row?.status === 'Pending' ? "text-warning" : "text-secondary"}>{row?.staff_status === "1" ? "Enable" : "Disable"}</span>
        },
        {
            name: 'Points',
            selector: row => row?.staff_wallet ? Number(row?.staff_wallet).toFixed(0) : 0,
        },
        {
            name: 'Transfer',
            selector: row => row?.fund_transfer_sum ? Number(row?.fund_transfer_sum).toFixed(0) : 0,
        },
        {
            name: 'View',
            selector: row => <>
                <svg onClick={() => navigate(`/user/staff-list/${name}/${id}/view-staff/${row?.staff_vcard}/${row?.id}`)} className="pointer" xmlns="http://www.w3.org/2000/svg" width="22.771" height="22.771" viewBox="1746.229 341.416 22.771 22.771"><path d="M1756.102 341.444c-5.445 0-9.873 4.429-9.873 9.873 0 5.444 4.428 9.873 9.873 9.873 1.664 0 3.3-.395 4.682-1.157.111.134.234.256.367.367l2.82 2.821a2.877 2.877 0 1 0 4.063-4.062l-2.82-2.82a2.821 2.821 0 0 0-.452-.368c.761-1.382 1.24-2.99 1.24-4.682 0-5.445-4.428-9.873-9.872-9.873Zm0 2.82a7.019 7.019 0 0 1 7.052 7.053c0 1.862-.677 3.582-1.862 4.852l-.085.084c-.133.111-.256.234-.366.367-1.241 1.129-2.934 1.777-4.768 1.777a7.019 7.019 0 0 1-7.052-7.052 7.019 7.019 0 0 1 7.052-7.052Z" fill="#dededf" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
            </>
        }
    ];

    return (
        <div className="stafflist-page">
            {/* <h1 className="stafflist-t1">
                User List 
                <button onClick={() => navigate('/user/add')} className="btn-1 ms-5" style={{position: 'relative', top: '-6px'}}>
                    Add user 
                    <svg className="ms-3" xmlns="http://www.w3.org/2000/svg" width="20.587" height="20.587" viewBox="838.955 86 20.587 20.587"><path d="M858.071 94.088h-6.617V87.47a1.47 1.47 0 0 0-1.47-1.47h-1.47a1.47 1.47 0 0 0-1.471 1.47v6.618h-6.617a1.47 1.47 0 0 0-1.47 1.47v1.47c0 .813.658 1.471 1.47 1.471h6.617v6.617c0 .812.658 1.47 1.47 1.47h1.47a1.47 1.47 0 0 0 1.471-1.47V98.5h6.617a1.47 1.47 0 0 0 1.47-1.47v-1.47a1.47 1.47 0 0 0-1.47-1.471Z" fill="#fff" fill-rule="evenodd" data-name="Icon awesome-plus"/></svg>
                </button>
            </h1> */}
            <h1 className="staffdetail-t1">{name}</h1>
            <h6 className="staffdetail-t2"> 
                <span onClick={() => navigate(`/users`)} className="pointer">
                    Users 
                </span>
                <span className="greater-than"> &#62; </span> 
                <span onClick={() => navigate(`/view-user/${id}`)} className="pointer">
                    {name}
                </span>
                <span className="greater-than"> &#62; </span> 
                <span className="staffdetail-t3">List VCard</span>
            </h6>
            <div className="d-flex justify-content-between mt-5">
                <div className="pt-4">
                    <h3 className="stafflist-t2 mb-0" style={{position: 'relative', top: '-12px'}}>VCard List ({filteredStaffList?.length ? filteredStaffList?.length : 0})</h3>
                </div>
                <div>
                    <div className="p-relative">
                        <input onChange={handleSearch} type="text" style={{width: '300px'}} className="form-control" />
                        <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="23.062" height="23.062" viewBox="1764.938 75.468 23.062 23.062"><path d="M1774.937 75.497c-5.514 0-10 4.485-10 10 0 5.513 4.486 9.998 10 9.998 1.685 0 3.342-.4 4.742-1.171.112.135.237.26.372.371l2.856 2.857a2.914 2.914 0 1 0 4.114-4.114l-2.857-2.857a2.857 2.857 0 0 0-.457-.371c.772-1.4 1.257-3.028 1.257-4.742 0-5.514-4.485-10-9.999-10Zm0 2.857a7.109 7.109 0 0 1 7.142 7.142c0 1.886-.686 3.628-1.886 4.914l-.085.086c-.135.112-.26.236-.372.371-1.257 1.143-2.97 1.8-4.828 1.8a7.109 7.109 0 0 1-7.142-7.142 7.109 7.109 0 0 1 7.142-7.143Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                    </div>
                </div>
            </div>
            <div className="staff-table-div mt-4 pt-3">
                {loading ? (
                    <Loader />
                ) : (
                    <DataTableBase 
                        columns={columns}
                        data={filteredStaffList}
                        pagination
                    />
                )}
            </div>
        </div>
    )
}

export default ListStaff;