import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactDatePicker from "../../common/ReactDatePicker";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utility";
import Loader from "../../common/Loader";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

function formate1(dt){
    const formattedDate = new Date(dt).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    console.log(formattedDate);
    return formattedDate;
}

const ViewStaff = () => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loading2, setLoading2] = useState(false);
    const [staff, setStaff] = useState(null);

    const {register, reset, handleSubmit, formState: {errors}} = useForm();

    const navigate = useNavigate();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const { id, sid, name } = useParams();

    const [flag, setFlag] = useState(1);

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/users/${id}/${sid}/staff`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Staff res : ", res);
            setStaff(res?.data?.staffDetail);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("staff error : ", err);
        })
    }, [flag])

    const onSubmit = (data) => {
        setLoading2(true);
        const token = localStorage.getItem("admin_token");
        axios.post(`${API_URL}/admin/users/userVcardUpdate`, {...data, staff_id: staff?.id}, 
            {headers: {Authorization: `Bearer ${token}`}}
        ).then((res) => {
            console.log("upate v card : ", res);
            setLoading2(false);
            setFlag(flag + 1);
            toast.success(res?.data?.message);
        }).catch((err) => {
            setLoading2(false);
            setFlag(flag + 1);
            console.log("Error update v card : ", err);
            toast.error(err?.response?.data?.error);
        })
    }

    return (
        <div className="staff-detail-page">
            <ToastContainer />
            <h1 className="staffdetail-t1">{name}</h1>
            <h6 className="staffdetail-t2"> 
                <span className="pointer" onClick={() => navigate(`/users`)}>
                    Users 
                </span>
                <span className="greater-than"> &#62; </span>
                <span className="pointer" onClick={() => navigate(`/view-user/${id}`)}>
                    {name}
                </span> 
                <span className="greater-than"> &#62; </span>
                {/* <span className="greater-than"> &#62; </span> */}
                <span className="pointer" onClick={() => navigate(`/user/staff-list/${name}/${id}`)}>
                    List VCard 
                </span>
                {!loading && (
                    <>
                        <span className="greater-than"> &#62; </span> 
                        {staff?.staff_vcard}
                    </>
                )}
                <span className="greater-than"> &#62; </span> 
                <span className="staffdetail-t3">View VCard</span> 
            </h6>
            <h3 className="staffdetail-sub-heading mt-3 mb-0">Information</h3>
            {loading ? (<Loader />) : (
                <>
                    <div className="d-flex mt-4 pt-2">
                        <div className="div1">
                            {/* <p className="mb-0">User account</p> */}
                            <p className="mb-0">Status</p>
                            <p className="mb-0">Points</p>
                            <p className="mb-0">VIN</p>
                            <p className="mb-0">RFID No.</p>
                            <p className="mb-0">Volta</p>
                            <p className="mb-0">Model</p>
                            <p className="mb-0">Color Description</p>
                            <p className="mb-0">Registration No.</p>
                            <p className="mb-0">Transfer</p>
                            {/* <p className="mb-0">Name - Surname</p> */}
                            {/* <p className="mb-0">Address</p> */}
                            {/* <p className="mb-0">Email</p> */}
                            {/* <p className="mb-0">Phone</p> */}
                            {/* <p className="mb-0">Company</p> */}
                            <p className="mb-0">VCard</p>
                            {/* <p className="mb-0">V-Card status</p> */}
                        </div>
                        <div className="div2">
                            {/* <p className="mb-0">Kerry</p> */}
                            {staff?.staff_status === "1" ? (
                                <p className="mb-0 color-42c78f">Enable</p>
                                ) : (
                                <p className="mb-0 text-danger">Disable</p>
                            )}
                            <p className="mb-0">{staff?.staff_wallet ? Number(staff?.staff_wallet).toFixed(0) : 0}</p>
                            <p className="mb-0">{staff?.vin_id}</p>
                            <p className="mb-0">{staff?.rfid_no}</p>
                            <p className="mb-0">{staff?.volta}</p>
                            <p className="mb-0">{staff?.model}</p>
                            <p className="mb-0">{staff?.color_desc}</p>
                            <p className="mb-0">{staff?.registration_no}</p>
                            <p className="mb-0">{Number(staff?.fund_transfer_sum).toFixed(0)}</p>
                            {/* <p className="mb-0">Angleria Horgan</p> */}
                            {/* <p className="mb-0">6658 Ladpao Road Jungrasem Jatujak Bangkok 10900</p> */}
                            {/* <p className="mb-0">angleria_2539@gmail.com</p> */}
                            {/* <p className="mb-0">0889563324</p> */}
                            {/* <p className="mb-0">Kerry Express</p> */}
                            <p className="mb-0">{staff?.staff_vcard}</p>
                            {/* <p className="mb-0"> */}
                                {/* <span className="color-42c78f">Verify</span> */}
                                {/* <button onClick={handleShow} className="btn-2 ms-4">Re-verify</button> */}
                            {/* </p> */}
                        </div>
                    </div>
                    
                    
                    <h3 className="staffdetail-sub-heading mt-4 mb-0">Allocate fund</h3>
                    <div className="d-flex mt-4 pt-2">
                        <div className="div1">
                            <p className="mb-0">Allocate</p>
                        </div>
                        <div className="div2">
                            {/* <p className="mb-0">Every 15th day of the month</p> */}
                            {staff?.fund_detial ? (
                                staff?.allocation_type === "week" ? (
                                    <p className="mb-0">{staff?.fund_detial?.funding_amount} - Every week on {staff?.fund_detial?.allocation_day} at {staff?.fund_detial?.allocation_time} </p>
                                ): staff?.fund_detial?.allocation_type === "month" ? (
                                    <p className="mb-0">{staff?.fund_detial?.funding_amount} - Every month on {staff?.fund_detial?.allocation_day}{staff?.fund_detial?.allocation_day === "1" ? 'st': staff?.fund_detial?.allocation_day === "2" ? 'nd' : 'th'} at {staff?.fund_detial?.allocation_time}</p>
                                ) :(
                                    <p className="mb-0">{staff?.fund_detial?.funding_amount} - Manual  {formate1(staff?.fund_detial?.allocation_date)} at {staff?.fund_detial?.allocation_time} (Once Only)</p>
                                )

                            ) : (
                                <p className="mb-0">Not Allocated</p>
                            )}
                        </div>
                    </div>
                    <div className="mb-5 pt-4">
                        <button className="btn-34 mt-4" onClick={() => navigate(`/user/staff-list/${name}/${id}/view-staff/report/${staff?.staff_vcard}/${sid}`)}>Charging Report</button>
                        <button className="btn-34 mt-4 mx-2" onClick={() => navigate(`/user/staff-list/${name}/${id}/view-staff/topping-report/${staff?.staff_vcard}/${sid}`)}>Topping Report</button>
                        <button className="btn-34 mt-4" onClick={() => {
                            reset({volta: staff?.volta, vin_id: staff?.vin_id, rfid_no: staff?.rfid_no});
                            handleShow();
                        }}>Edit</button>
                    </div>
                </>
            )}

            {/* model one  */}
            
            {/* <Modal show={show} onHide={handleClose} size="lg">
                <div className="verify-email-div">
                    <h1>Verify V-card</h1>
                    <p className="pt-4 mt-4 mb-4 pb-4">Please confirm verification. V card in <b>angleria_2539@gmail.com</b> within 24 hours</p>

                    <div className="text-center">
                        <button className="btn-3 btn-1 me-2" onClick={handleClose}>Send</button>
                        <button className="btn-2 ms-2" onClick={handleClose}>Cancel</button>
                    </div>

                </div>
            </Modal> */}
            <Modal show={show} onHide={handleClose} size="lg">
                <div className="verify-email-div">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <h1>EDIT VCard Details</h1>
                        <div>
                            <div className="mb-3">
                                <label className="ms-2">VIN ID</label>
                                <input type="text" {...register("vin_id", {required: true})} className="form-control" />
                                {errors?.vin_id && <span className="text-danger mb-0 mt-1">VIN ID is required!</span>}
                            </div>

                            <div className="mb-3">
                                <label className="ms-2">RFID No.</label>
                                <input type="text" {...register("rfid_no", {required: true})} className="form-control" />
                                {errors?.rfid_no && <span className="text-danger mb-0 mt-1">RFID No. is required!</span>}
                            </div>
                            <div className="mb-3">
                                <label className="ms-2">Volta</label>
                                <input type="text" {...register("volta", {required: true})} className="form-control" />
                                {errors?.volta && <span className="text-danger mb-0 mt-1">Volta is required!</span>}                                
                            </div>
                        </div>
                        <div className="text-center d-flex justify-content-center">
                            {loading2 ? (
                                <div style={{height: '30px', width: '80px'}}>
                                    <Spinner />
                                </div>
                            ) : (
                                <div>
                                    <button className="btn-3 btn-1 me-2" type="submit">Send</button>
                                </div>
                            )}
                            <button className="btn-2 ms-2" type="button" onClick={handleClose}>Cancel</button>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* model two  */}
            
            <Modal show={show2} onHide={handleClose2} size="lg">
                <div className="verify-email-div">
                    <h1>Edit allocate fund</h1>
                    <div className="px-2 py-3">
                        <h3>Fund</h3>
                        <div className="mt-3">
                            <input type="text" className="form-control d-inline-block" style={{width: 'calc(100% - 52px)'}} /> <label className="ms-2">THB</label>
                        </div>
                        <h3 className="mt-3">Automatic</h3>
                        <div className="mt-3">
                            <input className="p-2" type="radio" />
                            <label className="mx-2">Every</label>
                            <select className="form-control d-inline-block w-25">
                                <option>Select Day</option>
                            </select>
                            <label className="mx-2">and time</label>
                            <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                            <label className="ms-2">in week.</label>
                        </div>
                        
                        <div className="d-flex justify-content-between mt-3">
                            <div className="w-25 bdr-t"></div>
                            <div className="w-15" style={{width: '15%'}}><lable>Or</lable></div>
                            <div className="w-25 bdr-t"></div>
                        </div>
                        
                        <div className="mt-3">
                            <input className="p-2" type="radio" />
                            <label className="mx-2">Every</label>
                            <select className="form-control d-inline-block w-25">
                                <option>Select Day</option>
                            </select>
                            <label className="mx-2">and time</label>
                            <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                            <label className="ms-2">in month.</label>
                        </div>

                        <h3 className="mt-3">Manual</h3>
                        
                        <div className="mt-3">
                            <input className="p-2" type="radio" />
                            <div className="d-inline-block ms-2">
                                <ReactDatePicker />
                            </div>                            
                            <label className="mx-2">and time</label>
                            <input type="text" placeholder="9:00" className="form-control w-25 d-inline-block" />
                            <label className="ms-2">in month.</label>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button className="btn-3 btn-1 me-2" onClick={handleClose2}>Send</button>
                        <button className="btn-2 ms-2" onClick={handleClose2}>Cancel</button>
                    </div>

                </div>
            </Modal>

        </div>
    )
}

export default ViewStaff;