import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const AddFundRequest = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="staff-detail-page edit-profile">
            <h1 className="staffdetail-t1">Create new quatation</h1>
            <h6 className="staffdetail-t2"> Fund request <span className="greater-than"> &#62; </span> <span className="staffdetail-t3">Create new quatation</span></h6>
            <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">Bill information</h3>
            
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0">Company</p>
                    <p className="mb-0">Company Address</p>
                    <p className="mb-0">Phone</p>
                    <p className="mb-0">TAX ID</p>
                    <p className="mb-0">Fund amount</p>
                </div>
                <div className="div2 w-75">
                    <p className="mb-0">
                        Kerry Express
                    </p>
                    <p className="mb-0">
                        1111/105 Baangrangmuang Ladpao Road Junkasem Jatujak Bangkok 10900
                    </p>
                    <p className="mb-0">
                        025788877
                    </p>
                    <p className="mb-0">
                        1233255489966
                    </p>
                    <p className="mb-0">
                        <input type="text" placeholder="1,000,000" className="form-control w-60 d-inline-block" />
                        <h6 className="thb-label">THB</h6>                                
                    </p>
                    
                </div>
            </div>
            
            <div className="mb-5 pt-4">
                <button className="btn-2 me-4">Edit</button>
                <button className="btn-3 mt-4" onClick={() => navigate('/fund-requests/add/transfer')}>Next</button>
            </div>
        </div>
    )
}

export default AddFundRequest;