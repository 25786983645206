import React, { useState } from "react";


const EditProfile = () => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="staff-detail-page edit-profile">
            <h1 className="staffdetail-t1">Edit profile</h1>
            <h6 className="staffdetail-t2"> Setting <span className="greater-than"> &#62; </span> <span className="staffdetail-t3">Edit Profile</span></h6>
            <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">Information</h3>
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0">Status</p>
                    <p className="mb-0">User Account</p>
                    {/* <p className="mb-0">Wallet</p> */}
                    <p className="mb-0">Name</p>
                    <p className="mb-0">Address</p>
                    <p className="mb-0">Email</p>
                    <p className="mb-0">Phone</p>
                    <p className="mb-0">Company</p>
                    {/* <p className="mb-0">V-Card status</p> */}
                </div>
                <div className="div2 w-50">
                    <p className="mb-0 color-42c78f">
                        <div className='switch-btn-div pt-31' style={{position: 'relative', top: '-4px'}}>
                            <input type="checkbox" defaultChecked={true}  name="check" value={true} class="toggle-station"/>                            
                        <span className="switch-active-label">Active</span>
                        </div>
                    </p>
                    <p className="mb-0">Kerry</p>
                    <p className="mb-0 w-100">
                        <div className="d-flex w-100" >
                            <div className="w-50">
                                <input type="text" defaultValue="John" className="form-control w-100" />
                            </div>
                            <div className="pt-2">
                                <p className="mb-0 text-normal ms-4 ps-3 me-3">Surname</p>
                            </div>
                            <div className="w-50">
                                <input type="text" defaultValue="Wick" className="form-control w-100" />                                
                            </div>
                        </div>
                    </p>
                    <p className="mb-0 ">
                        <input type="text" defaultValue={"1663/404 Pattanakan Road Suanluang Suanluang Bangkok 10250"} className="form-control w-100" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"design@trigongroup.net"} className="form-control w-100" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"038382292929"} className="form-control w-100" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"Kerry Express"} className="form-control w-100" />                                
                    </p>
                    {/* <p className="mb-0">
                        <span className="color-42c78f">Verify</span>
                        <button className="btn-2 ms-4">Re-verify</button>
                    </p> */}
                </div>
            </div>
            <h3 className="staffdetail-sub-heading mt-4 mb-0">Bill information</h3>
            <div className="d-flex mt-3">
                <div className="w-25">
                    <div className="p-relative">
                        <input type={showPassword ? "text" : "password"} defaultValue={"Kerry Express"} className="form-control w-100" />                                
                        <svg onClick={() => setShowPassword(!showPassword)} className="eye-iconn" xmlns="http://www.w3.org/2000/svg" width="33.75" height="22.5" viewBox="740 759.75 33.75 22.5"><path d="M773.546 770.145c-3.177-6.2-9.468-10.395-16.671-10.395-7.203 0-13.495 4.198-16.671 10.395a1.896 1.896 0 0 0 0 1.71c3.177 6.2 9.468 10.395 16.671 10.395 7.203 0 13.495-4.198 16.671-10.395a1.895 1.895 0 0 0 0-1.71Zm-16.671 9.293a8.438 8.438 0 1 1 8.438-8.438 8.433 8.433 0 0 1-8.438 8.438Zm0-14.063c-.502.007-1 .082-1.483.222a2.804 2.804 0 0 1-3.92 3.92 5.612 5.612 0 1 0 5.403-4.142Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon awesome-eye"/></svg>
                    </div>
                </div>
                <div>
                    <button className="generate-pass-btn ms-3">Generate</button>
                </div>
            </div>

            <h3 className="staffdetail-sub-heading mt-4 mb-0">Bill information</h3>
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0">Company</p>
                    <p className="mb-0">Company Address</p>
                    <p className="mb-0">Phone</p>
                    <p className="mb-0">TAX ID</p>
                </div>
                <div className="div2 w-50">
                    <p className="mb-0">
                        <input type="text" defaultValue={"Kerry Express"} className="form-control w-50" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"1111/105 Baangrangmuang Ladpao Road Junkasem Jatujak Bangkok 10900"} className="form-control w-100" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"025788877"} className="form-control w-100" />                                
                    </p>
                    <p className="mb-0">
                        <input type="text" defaultValue={"1233255489966"} className="form-control w-100" />                                
                    </p>
                    
                </div>
            </div>
            <h3 className="staffdetail-sub-heading mt-4 mb-0">Payment method</h3>
            <div className="d-flex mt-4 pt-2">
                <div className="div1">
                    <p className="mb-0">Card</p>
                </div>
                <div className="div2">
                    <p className="mb-0">Visa ending ****9999 
                        <span className="setting-t1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16.293" height="16.293" viewBox="821.73 909.666 16.293 16.293"><path d="M836.064 918.627c.041-.244.041-.53.041-.814 0-.286-.041-.53-.041-.815l1.75-1.344c.166-.122.208-.326.083-.53l-1.667-2.81c-.083-.163-.333-.245-.5-.163l-2.083.814a6.064 6.064 0 0 0-1.416-.814l-.292-2.16a.446.446 0 0 0-.416-.325h-3.333a.446.446 0 0 0-.417.326l-.333 2.159c-.5.203-.958.488-1.417.814l-2.083-.814c-.208-.082-.417 0-.5.163l-1.666 2.81a.492.492 0 0 0 .083.53l1.791 1.344c0 .285-.041.53-.041.815 0 .285.041.529.041.814l-1.75 1.344c-.166.122-.207.326-.083.53l1.667 2.81c.083.164.333.245.5.164l2.083-.815c.417.326.916.61 1.416.815l.334 2.158c.041.204.208.326.416.326h3.333a.446.446 0 0 0 .417-.326l.333-2.158c.5-.204.959-.49 1.417-.815l2.083.815c.208.08.416 0 .5-.164l1.666-2.81a.491.491 0 0 0-.083-.53l-1.833-1.344Zm-6.208 2.037c-1.625 0-2.916-1.263-2.916-2.851 0-1.59 1.291-2.852 2.916-2.852s2.916 1.263 2.916 2.852c0 1.588-1.29 2.85-2.916 2.85Z" fill="#303778" fill-rule="evenodd" data-name="Icon ionic-md-settings"/></svg>
                            Edit
                        </span>
                    </p>
                </div>
            </div>
            
            <div className="mb-5 pt-4">
                <button className="btn-3 mt-4">Edit</button>
                <button className="btn-2 ms-4">Cancel</button>
            </div>
        </div>
    )
}

export default EditProfile;