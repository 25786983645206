import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


const TransferOption = () => {
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();

    return (
        <div className="staff-detail-page edit-profile">
            <h1 className="staffdetail-t1">Create new quatation</h1>
            <h6 className="staffdetail-t2"> 
                Fund request 
                <span className="greater-than"> &#62; </span>
                Create new quatation 
                <span className="greater-than"> &#62; </span>
                <span className="staffdetail-t3">Transfer</span>
            </h6>
            
            <h3 className="staffdetail-sub-heading mt-4 pt-4 mb-0">Transfer Option</h3>
            
            <div className="d-flex mt-4 pt-2">
                <div>
                    <p className="mb-0 bg-secondary1">
                        <input type="radio" name="transfer" id="qr_code" />
                    </p>
                    <p className="mb-0 bg-info1 pt-1">
                        <input type="radio" name="transfer" id="bank_transfer" />
                    </p>
                    <p className="mb-0 bg-primary1 pt-1">
                        <input type="radio" id="card" name="transfer" />
                    </p>
                </div>
                <div className="div2 w-75 ms-3">
                      <p className="mb-0 transfer-t1 bg-danger1"><label className="pointer" for="qr_code">QR Code</label></p>
                      <p className="mb-0 transfer-t1 bg-warning1"><label className="pointer" for="bank_transfer">Bank Transfer</label></p>
                      <p className="mb-0 transfer-t1 bg-success1"><label className="pointer" for="card">Card With</label>
                        <select className="form-control w-25 d-inline-block py-1 without-bg-select" style={{height: 'auto', border: 'none'}}>
                            <option value="">Visa ending ****9999</option>
                        </select>
                      </p>
                </div>
            </div>
            
            <div className="mb-5 pt-4">
                <button className="btn-2 me-4">Back</button>
                <button className="btn-3 mt-4" onClick={() => navigate('/fund-requests/add/transfer/qr-code')}>Next</button>
            </div>
        </div>
    )
}

export default TransferOption;