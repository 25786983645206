import React, { useEffect, useState } from "react";
import DataTableBase from "../../common/DateTableBase";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../utility";
import Loader from "../../common/Loader";

const dataa = [
    {
        id:'#q5d9fcf7',
        staff: 'Saff A',
        name: 'Angleria Horgan',
        status: 'Active',
        wallet: 2000,
        date: '18-09-2023',
        time: '18:30',
        transfer: 'QR Code',
    },
    {
        id:'#q5s9sc57',
        staff: 'Saff B',
        name: 'Ethan Hunt',
        status: 'Inactive',
        date: '12-05-2023',
        time: '14:30',
        transfer: 'Bank transfer',
        wallet: 5000
    },
    {
        id:'#qj3d937',
        staff: 'Saff C',
        name: 'The Rock',
        time: '16:23',
        status: 'Pending',
        date: '17-08-2023',
        transfer: 'Credit card',
        wallet: 3000
    },
    {
        id:'#q3d3f0f7',
        staff: 'Saff D',
        name: 'Wyn Johnson',
        status: 'Incomplete',
        date: '11-01-2023',
        time: '19:27',
        transfer: 'Bank transfer',
        wallet: 5000
    },
]

const FundRequest = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("admin_token");
        
        // console.log("token : ", token);
        axios.get(`${API_URL}/admin/funds`, {headers: {'Authorization': `Bearer ${token}`}}).then((res) => {
            console.log("Fund request list : ", res);
            setData(res?.data?.data);
            setFilteredData(res?.data?.data);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.log("Fund request list error : ", err);
        })

    }, [])

    const handleChange = (e) => {
        const value = e.target.value;
        const filteredArray = data?.filter(item => item?.request_id?.toLowerCase()?.includes(value?.toLowerCase()) || item?.transfer_type?.toLowerCase()?.includes(value?.toLowerCase()) || item?.fund_amount.toString()?.includes(value) || item?.status?.toLowerCase()?.includes(value?.toLowerCase()))
        setFilteredData(filteredArray);
    }

    const columns = [
        {
            name: 'No.',
            width: '120px',
            selector: row => row?.request_id,
        },
        {
            name: 'Date',
            width: '105px',
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[0];
                    return formateDate;                
                }
            },
        },
        {
            name: 'Time',
            width: '90px',
            selector: row => {
                if(row?.created_at){
                    let date = new Date(row?.created_at);
                    let formateDate = date.toISOString()?.split('T')[1];
                    let time = formateDate?.split('.')[0];
                    let formateTime = time?.split(':');
                    return `${formateTime[0]}:${formateTime[1]}`;
                }
            },
        },
        {
            name: 'Company',
            selector: row => row?.company_name,
        },
        {
            name: 'Transfer',
            width: '150px',
            selector: row => row?.transfer_type,
        },
        {
            name: 'Status',
            width: '120px',
            selector: row => <span className={row?.status === 'Success' ? "text-success" : row?.status === 'Failed'? "text-danger" : row?.status === 'Pending' ? "text-warning" : "text-secondary"}>{row?.status}</span>
        },
        {
            name: 'Fund Request',
            width: '130px',
            selector: row => Number(Number(row?.fund_amount).toFixed(2)).toLocaleString(),
        },
        {
            name: 'View',
            width: '80px',
            selector: row => <>
                <button className="title-btns" title="Fund Detail">
                    <svg className="pointer" onClick={() => navigate(`/fund-request/detail/${row?.id}`)} xmlns="http://www.w3.org/2000/svg" width="22.771" height="22.771" viewBox="1746.229 438.416 22.771 22.771"><path d="M1756.102 438.444c-5.445 0-9.873 4.429-9.873 9.873 0 5.444 4.428 9.873 9.873 9.873 1.664 0 3.3-.395 4.682-1.157.111.134.234.256.367.367l2.82 2.821a2.877 2.877 0 1 0 4.063-4.062l-2.82-2.82a2.821 2.821 0 0 0-.452-.368c.761-1.382 1.24-2.99 1.24-4.682 0-5.445-4.428-9.873-9.872-9.873Zm0 2.82a7.019 7.019 0 0 1 7.052 7.053c0 1.862-.677 3.582-1.862 4.852l-.085.084c-.133.111-.256.234-.366.367-1.241 1.129-2.934 1.777-4.768 1.777a7.019 7.019 0 0 1-7.052-7.052 7.019 7.019 0 0 1 7.052-7.052Z" fill="#7c7f84" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                </button>
            </>
        }
    ];

    return (
        <div className="stafflist-page">
            <h1 className="stafflist-t1 fundrequest-t1">
                Fund List 

            </h1>
            <div className="d-flex justify-content-between mt-2">
                <div className="pt-4">
                    <h3 className="stafflist-t2 mb-0">Fund List</h3>
                </div>
                <div>
                    <div className="p-relative">
                        <input onChange={handleChange} type="text" style={{width: '300px'}} className="form-control" />
                        <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="23.062" height="23.062" viewBox="1764.938 75.468 23.062 23.062"><path d="M1774.937 75.497c-5.514 0-10 4.485-10 10 0 5.513 4.486 9.998 10 9.998 1.685 0 3.342-.4 4.742-1.171.112.135.237.26.372.371l2.856 2.857a2.914 2.914 0 1 0 4.114-4.114l-2.857-2.857a2.857 2.857 0 0 0-.457-.371c.772-1.4 1.257-3.028 1.257-4.742 0-5.514-4.485-10-9.999-10Zm0 2.857a7.109 7.109 0 0 1 7.142 7.142c0 1.886-.686 3.628-1.886 4.914l-.085.086c-.135.112-.26.236-.372.371-1.257 1.143-2.97 1.8-4.828 1.8a7.109 7.109 0 0 1-7.142-7.142 7.109 7.109 0 0 1 7.142-7.143Z" fill="#d2d2d2" fill-rule="evenodd" data-name="Icon open-magnifying-glass"/></svg>
                    </div>
                </div>
            </div>
            <div className="staff-table-div mt-4 pt-3">
            {loading ? (
                    <Loader />
                ) : (
                <DataTableBase 
                    columns={columns}
                    data={filteredData}
                    pagination
                />
            )}
            </div>
        </div>
    )
}

export default FundRequest;